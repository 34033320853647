.my-address .add-fa{
    font-size: 30px;
}
.btn-address{
    color: var(--black);
}
.my-address .btn-address:hover{
    color: #D10024;
}
.select-address{
    text-align: left;
}
.select-address input{
    border: none !important;
}
.select-address{
    width: calc(100% - 39px);
}
.select-address .css-qc6sy-singleValue{
    color: var(--black) !important;
    /* font-size: 1rem !important; */
    font-weight: 400;
    text-transform: lowercase;
}
.select-address div::first-letter{
    text-transform: uppercase;
}
.address-card p{
    margin-bottom: 2px;
}
.address-btn-link .btn{
    font-size: 14px;
    width: 100%;
    padding: 4px;
}