.address-modl .input-group-text{
    width: 40px;
}
.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    border: 1px solid;
    border-radius: 0.25rem;
    color: #768192;
    background-color: #ebedef;
    border-color: #d8dbe0;
}
.custom-form .input-group-text{
    margin-right: -3px;
}
.custom-form .fa-lock{
    font-size: 22px;
}
.custom-form .fa-user{
    font-size: 20px;
}
.link{
    margin-top: 30px;
    font-size: 14px;
}
.link-highlight{
    color: blueviolet;
}
.link-highlight:hover{
    color: #D10024;
}
.input-info{
    font-size: 12px;
    float: right;
    color: #768192;
    font-weight: 500;
}
.icon-eye{
    margin: 12px;
    cursor: pointer;
}
.image-preview{
    width: 150px;
    height: 150px;
    border-radius: 15px;
    background: #ddd;
}
.img-wrap {
    position: relative;
    width: 150px;
    height: 150px;
    /* background: #ddd; */
    /* border-radius: 15px; */
}
.img-wrap .delete {
    position: absolute;
    top: -4px;
    right: 0px;
    z-index: 100;
    background: #d10024;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    text-align: center;
    cursor: pointer;
    line-height: 1.3;
    color: white;
    font-size: 20px;
}
.scroll-150{
    height: 150px;
    overflow: auto;
}