.cart-section .nav-tabs .nav-link.active{
    background: var(--secondary);
    color: white !important;
}
.cart-section .nav-tabs{
    background: #dfdfdf;
}
.cart-section .nav-tabs .nav-link,
.cart-section .nav-tabs{
    border: none;
    border-top-right-radius: 4.25rem;
}
.cart-section .nav-tabs .nav-link{
    font-weight: 600;
}
.cart-section .btn-sky{
    background: linear-gradient(95deg,#e2fdfd -12%,#b5f4f6 83%,#e8f9f9 109%),linear-gradient(to bottom,#fff,#fff);
    color: #08696b;
    height: 30px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    display: inline-table;
}