.product-image .image{
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    width: 70px;
    height: 75px;
    margin: 3px auto;
    cursor: pointer;
}
.product-image .image-wrap{
    /* border: 1px solid #f00; */
    /* margin: 8px auto; */
    padding: 3px;
}
.product-image .image-wrap.active{
    border: 1px solid var(--secondary);
}
.product-image{
    width: 90px;
    margin: 0px 8px 20px;
}
.image-wrapper{
    display: inline-flex;
    /* padding: 15px 5px; */
    padding: 10px 15px 15px 5px;
    width: 100%;
}
.image-wrapper .main-image{
    margin-left: 12px;
    width: 100%;
    margin-top: 6px;
}
.image-wrapper .main-image img{
    width: 100%;
    height: 60vh;
}
.image-wrapper .main-image .js-image-zoom__zoomed-image{
    z-index: 999;
}
.measure-content .title{
    color: #801f1a75;
}
.measure-content .measure-wrap{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid #c59896;
    border-radius: 5px;
    margin: 8px 12px;
    padding: 10px;
    cursor: pointer;
}
.measure-content .measure-wrap p{
    margin-bottom: 0px;
}
.measure-content .measure-wrap .product-price{
    /* font-weight: 500; */
    font-weight: 600;
    display: inline-flex;
}
.measure-content .measure-wrap .qty{
    color: var(--black);
    font-weight: 500;
}
.measure-content .fa-check-square, .measure-content .fa-check-square-o{
    font-size: 20px;
}
.measure-content .measure-wrap.active, .measure-content .measure-wrap.active:hover{
    /* background: var(--secondary-light); */
    background: #ffa5002e;
    /* cursor: none; */
}
.measure-content .measure-wrap:hover{
    /* background: #ffa5002e; */
    background: var(--secondary-light);
}
/* .item-desc{
    margin: 12px;
} */
.item-desc h2{
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
}
.item-desc .nav{
    /* background: #f9f9f9; */
    padding: 0px 12px;
}
.nav-tabs .nav-link.active{
    font-weight: 600;
}
.nav-link{
    color: var(--black) !important;
}
.item-desc p, .item-desc span{
    color: #6a1e1e;
}
.item-desc .responsive{
    max-height: 350px;
    overflow: auto;
}
.grouped-section img{
    height: 50px;
    width: 50px;
    margin: 5px;
}
.grouped-section .grouped-content{
    display: inline-flex;
    padding: 0px 12px 12px;
    text-align: center;
    overflow: auto;
    width: 100%;
}
.grouped-section .grouped-content div{
    border: 1px dashed var(--black);
    margin-right: 12px;
}
.grouped-section .grouped-content div p{
    border-top: 1px dashed var(--black);
    padding: 4px 6px;
    margin: 0px;
    font-weight: 400;
}