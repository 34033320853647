/* .pro-detail{
    display: flex;
} */
.pro-detail .param-link i{
    font-size: 12px;
}
.pro-detail .product-price{
    font-size: 20px;
}
.pro-detail .param-list{
    font-weight: 500;
}
.pro-detail .icon{
    height: 40px;
    width: 40px;
    margin-right: 12px;
}
.pro-detail .divider{
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgb(223, 227, 230);
}
.pro-detail .add-to-cart{
    text-align: right;
    float: right;
}
.pro-detail .add-to-cart-btn{
    font-size: 15px !important;
    height: 40px !important;
}
.match-pro .product-test .test-param{
    height: 90px;
}
.match-pro .product-name{
    font-size: 15px !important;
}
.match-pro .price-cart{
    display: flex;
}