/*--------------------------------

Lineicons Free Web Font
Crafted by Lineicons.com Team
Version: 4.0

-------------------------------- */

@font-face {
    font-family: 'lineicons';
    src: url('../fonts/lineicons.eot');
    src: url('../fonts/lineicons.eot') format('embedded-opentype'),
         url('../fonts/lineicons.woff2') format('woff2'),
         url('../fonts/lineicons.woff') format('woff'),
         url('../fonts/lineicons.ttf') format('truetype'),
         url('../fonts/lineicons.svg') format('svg');
  }
  
  /* base class */
  .lni {
    display: inline-block;
    font: normal normal normal 1em/1 'lineicons';
    color: inherit;
    flex-shrink: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  /* size examples - relative units */
  .lni-sm {
    font-size: 0.8em;
  }
  
  .lni-lg {
    font-size: 1.2em;
  }
  
  /* size examples - absolute units */
  .lni-16 {
    font-size: 16px;
  }
  
  .lni-32 {
    font-size: 32px;
  }
  
  /* rotate the icon infinitely */
  .lni-is-spinning {
    animation: lni-spin 1s infinite linear;
  }
  
  @keyframes lni-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* transform */
  .lni-rotate-90 {
    transform: rotate(90deg);
  }
  
  .lni-rotate-180 {
    transform: rotate(180deg);
  }
  
  .lni-rotate-270 {
    transform: rotate(270deg);
  }
  
  .lni-flip-y {
    transform: scaleY(-1);
  }
  
  .lni-flip-x {
    transform: scaleX(-1);
  }
  
  /* icons */
  .lni-zoom-out::before {
    content: "\ea02";
  }
  
  .lni-zoom-in::before {
    content: "\ea03";
  }
  
  .lni-zip::before {
    content: "\ea04";
  }
  
  .lni-website::before {
    content: "\ea05";
  }
  
  .lni-website-alt::before {
    content: "\ea06";
  }
  
  .lni-users::before {
    content: "\ea07";
  }
  
  .lni-user::before {
    content: "\ea08";
  }
  
  .lni-upload::before {
    content: "\ea09";
  }
  
  .lni-unlink::before {
    content: "\ea0a";
  }
  
  .lni-trash-can::before {
    content: "\ea0b";
  }
  
  .lni-timer::before {
    content: "\ea0c";
  }
  
  .lni-tab::before {
    content: "\ea0d";
  }
  
  .lni-star-half::before {
    content: "\ea0e";
  }
  
  .lni-star-fill::before {
    content: "\ea0f";
  }
  
  .lni-star-empty::before {
    content: "\ea10";
  }
  
  .lni-signal::before {
    content: "\ea11";
  }
  
  .lni-shortcode::before {
    content: "\ea12";
  }
  
  .lni-shopping-basket::before {
    content: "\ea13";
  }
  
  .lni-share::before {
    content: "\ea14";
  }
  
  .lni-share-alt::before {
    content: "\ea15";
  }
  
  .lni-search::before {
    content: "\ea16";
  }
  
  .lni-search-alt::before {
    content: "\ea17";
  }
  
  .lni-scroll-down::before {
    content: "\ea18";
  }
  
  .lni-save::before {
    content: "\ea19";
  }
  
  .lni-rss-feed::before {
    content: "\ea1a";
  }
  
  .lni-rocket::before {
    content: "\ea1b";
  }
  
  .lni-remove-file::before {
    content: "\ea1c";
  }
  
  .lni-reload::before {
    content: "\ea1d";
  }
  
  .lni-printer::before {
    content: "\ea1e";
  }
  
  .lni-plug::before {
    content: "\ea1f";
  }
  
  .lni-package::before {
    content: "\ea20";
  }
  
  .lni-mouse::before {
    content: "\ea21";
  }
  
  .lni-mobile::before {
    content: "\ea22";
  }
  
  .lni-microphone::before {
    content: "\ea23";
  }
  
  .lni-mic::before {
    content: "\ea24";
  }
  
  .lni-menu::before {
    content: "\ea25";
  }
  
  .lni-map::before {
    content: "\ea26";
  }
  
  .lni-map-marker::before {
    content: "\ea27";
  }
  
  .lni-magnifier::before {
    content: "\ea28";
  }
  
  .lni-magnet::before {
    content: "\ea29";
  }
  
  .lni-link::before {
    content: "\ea2a";
  }
  
  .lni-laptop::before {
    content: "\ea2b";
  }
  
  .lni-laptop-phone::before {
    content: "\ea2c";
  }
  
  .lni-keyboard::before {
    content: "\ea2d";
  }
  
  .lni-image::before {
    content: "\ea2e";
  }
  
  .lni-headphone::before {
    content: "\ea2f";
  }
  
  .lni-harddrive::before {
    content: "\ea30";
  }
  
  .lni-game::before {
    content: "\ea31";
  }
  
  .lni-folder::before {
    content: "\ea32";
  }
  
  .lni-files::before {
    content: "\ea33";
  }
  
  .lni-empty-file::before {
    content: "\ea34";
  }
  
  .lni-download::before {
    content: "\ea35";
  }
  
  .lni-domain::before {
    content: "\ea36";
  }
  
  .lni-display::before {
    content: "\ea37";
  }
  
  .lni-display-alt::before {
    content: "\ea38";
  }
  
  .lni-database::before {
    content: "\ea39";
  }
  
  .lni-dashboard::before {
    content: "\ea3a";
  }
  
  .lni-cut::before {
    content: "\ea3b";
  }
  
  .lni-control-panel::before {
    content: "\ea3c";
  }
  
  .lni-cogs::before {
    content: "\ea3d";
  }
  
  .lni-cog::before {
    content: "\ea3e";
  }
  
  .lni-code::before {
    content: "\ea3f";
  }
  
  .lni-code-alt::before {
    content: "\ea40";
  }
  
  .lni-cloud-upload::before {
    content: "\ea41";
  }
  
  .lni-cloud-sync::before {
    content: "\ea42";
  }
  
  .lni-cloud-network::before {
    content: "\ea43";
  }
  
  .lni-cloud-download::before {
    content: "\ea44";
  }
  
  .lni-cloud-check::before {
    content: "\ea45";
  }
  
  .lni-camera::before {
    content: "\ea46";
  }
  
  .lni-calendar::before {
    content: "\ea47";
  }
  
  .lni-bulb::before {
    content: "\ea48";
  }
  
  .lni-bookmark::before {
    content: "\ea49";
  }
  
  .lni-bookmark-alt::before {
    content: "\ea4a";
  }
  
  .lni-bluetooth::before {
    content: "\ea4b";
  }
  
  .lni-ban::before {
    content: "\ea4c";
  }
  
  .lni-archive::before {
    content: "\ea4d";
  }
  
  .lni-alarm::before {
    content: "\ea4e";
  }
  
  .lni-alarm-clock::before {
    content: "\ea4f";
  }
  
  .lni-add-files::before {
    content: "\ea50";
  }
  
  .lni-thunder::before {
    content: "\ea51";
  }
  
  .lni-thunder-alt::before {
    content: "\ea52";
  }
  
  .lni-sun::before {
    content: "\ea53";
  }
  
  .lni-rain::before {
    content: "\ea54";
  }
  
  .lni-night::before {
    content: "\ea55";
  }
  
  .lni-drop::before {
    content: "\ea56";
  }
  
  .lni-cloudy-sun::before {
    content: "\ea57";
  }
  
  .lni-cloud::before {
    content: "\ea58";
  }
  
  .lni-travel::before {
    content: "\ea59";
  }
  
  .lni-surf-board::before {
    content: "\ea5a";
  }
  
  .lni-pyramids::before {
    content: "\ea5b";
  }
  
  .lni-underline::before {
    content: "\ea5c";
  }
  
  .lni-text-format::before {
    content: "\ea5d";
  }
  
  .lni-text-format-remove::before {
    content: "\ea5e";
  }
  
  .lni-text-align-right::before {
    content: "\ea5f";
  }
  
  .lni-text-align-left::before {
    content: "\ea60";
  }
  
  .lni-text-align-justify::before {
    content: "\ea61";
  }
  
  .lni-text-align-center::before {
    content: "\ea62";
  }
  
  .lni-strikethrough::before {
    content: "\ea63";
  }
  
  .lni-spellcheck::before {
    content: "\ea64";
  }
  
  .lni-sort-amount-dsc::before {
    content: "\ea65";
  }
  
  .lni-sort-amount-asc::before {
    content: "\ea66";
  }
  
  .lni-sort-alpha-asc::before {
    content: "\ea67";
  }
  
  .lni-pilcrow::before {
    content: "\ea68";
  }
  
  .lni-pagination::before {
    content: "\ea69";
  }
  
  .lni-page-break::before {
    content: "\ea6a";
  }
  
  .lni-line-spacing::before {
    content: "\ea6b";
  }
  
  .lni-line-double::before {
    content: "\ea6c";
  }
  
  .lni-line-dotted::before {
    content: "\ea6d";
  }
  
  .lni-line-dashed::before {
    content: "\ea6e";
  }
  
  .lni-italic::before {
    content: "\ea6f";
  }
  
  .lni-indent-increase::before {
    content: "\ea70";
  }
  
  .lni-indent-decrease::before {
    content: "\ea71";
  }
  
  .lni-bold::before {
    content: "\ea72";
  }
  
  .lni-spinner::before {
    content: "\ea73";
  }
  
  .lni-spinner-solid::before {
    content: "\ea74";
  }
  
  .lni-spinner-arrow::before {
    content: "\ea75";
  }
  
  .lni-hourglass::before {
    content: "\ea76";
  }
  
  .lni-tounge::before {
    content: "\ea77";
  }
  
  .lni-suspect::before {
    content: "\ea78";
  }
  
  .lni-speechless::before {
    content: "\ea79";
  }
  
  .lni-smile::before {
    content: "\ea7a";
  }
  
  .lni-sad::before {
    content: "\ea7b";
  }
  
  .lni-happy::before {
    content: "\ea7c";
  }
  
  .lni-friendly::before {
    content: "\ea7d";
  }
  
  .lni-cool::before {
    content: "\ea7e";
  }
  
  .lni-thought::before {
    content: "\ea7f";
  }
  
  .lni-t-shirt::before {
    content: "\ea80";
  }
  
  .lni-home::before {
    content: "\ea81";
  }
  
  .lni-gift::before {
    content: "\ea82";
  }
  
  .lni-cup::before {
    content: "\ea83";
  }
  
  .lni-crown::before {
    content: "\ea84";
  }
  
  .lni-briefcase::before {
    content: "\ea85";
  }
  
  .lni-spray::before {
    content: "\ea86";
  }
  
  .lni-flags-alt-1::before {
    content: "\ea87";
  }
  
  .lni-fireworks::before {
    content: "\ea88";
  }
  
  .lni-candy::before {
    content: "\ea89";
  }
  
  .lni-candy-cane::before {
    content: "\ea8a";
  }
  
  .lni-cake::before {
    content: "\ea8b";
  }
  
  .lni-baloon::before {
    content: "\ea8c";
  }
  
  .lni-trees::before {
    content: "\ea8d";
  }
  
  .lni-tree::before {
    content: "\ea8e";
  }
  
  .lni-sprout::before {
    content: "\ea8f";
  }
  
  .lni-mushroom::before {
    content: "\ea90";
  }
  
  .lni-mashroom::before {
    content: "\ea91";
  }
  
  .lni-leaf::before {
    content: "\ea92";
  }
  
  .lni-island::before {
    content: "\ea93";
  }
  
  .lni-flower::before {
    content: "\ea94";
  }
  
  .lni-bug::before {
    content: "\ea95";
  }
  
  .lni-volume::before {
    content: "\ea96";
  }
  
  .lni-volume-mute::before {
    content: "\ea97";
  }
  
  .lni-volume-medium::before {
    content: "\ea98";
  }
  
  .lni-volume-low::before {
    content: "\ea99";
  }
  
  .lni-volume-high::before {
    content: "\ea9a";
  }
  
  .lni-video::before {
    content: "\ea9b";
  }
  
  .lni-stop::before {
    content: "\ea9c";
  }
  
  .lni-shuffle::before {
    content: "\ea9d";
  }
  
  .lni-play::before {
    content: "\ea9e";
  }
  
  .lni-pause::before {
    content: "\ea9f";
  }
  
  .lni-gallery::before {
    content: "\eaa0";
  }
  
  .lni-full-screen::before {
    content: "\eaa1";
  }
  
  .lni-frame-expand::before {
    content: "\eaa2";
  }
  
  .lni-forward::before {
    content: "\eaa3";
  }
  
  .lni-backward::before {
    content: "\eaa4";
  }
  
  .lni-target-customer::before {
    content: "\eaa5";
  }
  
  .lni-seo::before {
    content: "\eaa6";
  }
  
  .lni-network::before {
    content: "\eaa7";
  }
  
  .lni-keyword-research::before {
    content: "\eaa8";
  }
  
  .lni-graph::before {
    content: "\eaa9";
  }
  
  .lni-bar-chart::before {
    content: "\eaaa";
  }
  
  .lni-warning::before {
    content: "\eaab";
  }
  
  .lni-unlock::before {
    content: "\eaac";
  }
  
  .lni-ticket::before {
    content: "\eaad";
  }
  
  .lni-ticket-alt::before {
    content: "\eaae";
  }
  
  .lni-thumbs-up::before {
    content: "\eaaf";
  }
  
  .lni-thumbs-down::before {
    content: "\eab0";
  }
  
  .lni-teabag::before {
    content: "\eab1";
  }
  
  .lni-shield::before {
    content: "\eab2";
  }
  
  .lni-radio-button::before {
    content: "\eab3";
  }
  
  .lni-question-circle::before {
    content: "\eab4";
  }
  
  .lni-protection::before {
    content: "\eab5";
  }
  
  .lni-power-switch::before {
    content: "\eab6";
  }
  
  .lni-pointer::before {
    content: "\eab7";
  }
  
  .lni-plus::before {
    content: "\eab8";
  }
  
  .lni-music::before {
    content: "\eab9";
  }
  
  .lni-more::before {
    content: "\eaba";
  }
  
  .lni-more-alt::before {
    content: "\eabb";
  }
  
  .lni-minus::before {
    content: "\eabc";
  }
  
  .lni-lock::before {
    content: "\eabd";
  }
  
  .lni-lock-alt::before {
    content: "\eabe";
  }
  
  .lni-list::before {
    content: "\eabf";
  }
  
  .lni-lifering::before {
    content: "\eac0";
  }
  
  .lni-key::before {
    content: "\eac1";
  }
  
  .lni-invention::before {
    content: "\eac2";
  }
  
  .lni-information::before {
    content: "\eac3";
  }
  
  .lni-infinite::before {
    content: "\eac4";
  }
  
  .lni-help::before {
    content: "\eac5";
  }
  
  .lni-heart::before {
    content: "\eac6";
  }
  
  .lni-heart-fill::before {
    content: "\eac7";
  }
  
  .lni-hand::before {
    content: "\eac8";
  }
  
  .lni-funnel::before {
    content: "\eac9";
  }
  
  .lni-flag::before {
    content: "\eaca";
  }
  
  .lni-flag-alt::before {
    content: "\eacb";
  }
  
  .lni-eye::before {
    content: "\eacc";
  }
  
  .lni-cross-circle::before {
    content: "\eacd";
  }
  
  .lni-close::before {
    content: "\eace";
  }
  
  .lni-circle-plus::before {
    content: "\eacf";
  }
  
  .lni-circle-minus::before {
    content: "\ead0";
  }
  
  .lni-checkmark::before {
    content: "\ead1";
  }
  
  .lni-checkmark-circle::before {
    content: "\ead2";
  }
  
  .lni-check-box::before {
    content: "\ead3";
  }
  
  .lni-bolt::before {
    content: "\ead4";
  }
  
  .lni-bolt-alt::before {
    content: "\ead5";
  }
  
  .lni-wheelchair::before {
    content: "\ead6";
  }
  
  .lni-weight::before {
    content: "\ead7";
  }
  
  .lni-syringe::before {
    content: "\ead8";
  }
  
  .lni-sthethoscope::before {
    content: "\ead9";
  }
  
  .lni-slim::before {
    content: "\eada";
  }
  
  .lni-skipping-rope::before {
    content: "\eadb";
  }
  
  .lni-pulse::before {
    content: "\eadc";
  }
  
  .lni-microscope::before {
    content: "\eadd";
  }
  
  .lni-hospital::before {
    content: "\eade";
  }
  
  .lni-heart-monitor::before {
    content: "\eadf";
  }
  
  .lni-first-aid::before {
    content: "\eae0";
  }
  
  .lni-dumbbell::before {
    content: "\eae1";
  }
  
  .lni-capsule::before {
    content: "\eae2";
  }
  
  .lni-basketball::before {
    content: "\eae3";
  }
  
  .lni-ambulance::before {
    content: "\eae4";
  }
  
  .lni-service::before {
    content: "\eae5";
  }
  
  .lni-restaurant::before {
    content: "\eae6";
  }
  
  .lni-pizza::before {
    content: "\eae7";
  }
  
  .lni-juice::before {
    content: "\eae8";
  }
  
  .lni-fresh-juice::before {
    content: "\eae9";
  }
  
  .lni-dinner::before {
    content: "\eaea";
  }
  
  .lni-coffee-cup::before {
    content: "\eaeb";
  }
  
  .lni-chef-hat::before {
    content: "\eaec";
  }
  
  .lni-burger::before {
    content: "\eaed";
  }
  
  .lni-write::before {
    content: "\eaee";
  }
  
  .lni-world::before {
    content: "\eaef";
  }
  
  .lni-world-alt::before {
    content: "\eaf0";
  }
  
  .lni-school-bench::before {
    content: "\eaf1";
  }
  
  .lni-school-bench-alt::before {
    content: "\eaf2";
  }
  
  .lni-pencil::before {
    content: "\eaf3";
  }
  
  .lni-pencil-alt::before {
    content: "\eaf4";
  }
  
  .lni-library::before {
    content: "\eaf5";
  }
  
  .lni-graduation::before {
    content: "\eaf6";
  }
  
  .lni-eraser::before {
    content: "\eaf7";
  }
  
  .lni-compass::before {
    content: "\eaf8";
  }
  
  .lni-clipboard::before {
    content: "\eaf9";
  }
  
  .lni-certificate::before {
    content: "\eafa";
  }
  
  .lni-book::before {
    content: "\eafb";
  }
  
  .lni-blackboard::before {
    content: "\eafc";
  }
  
  .lni-shift-right::before {
    content: "\eafd";
  }
  
  .lni-shift-left::before {
    content: "\eafe";
  }
  
  .lni-pointer-top::before {
    content: "\eaff";
  }
  
  .lni-pointer-right::before {
    content: "\eb00";
  }
  
  .lni-pointer-left::before {
    content: "\eb01";
  }
  
  .lni-pointer-down::before {
    content: "\eb02";
  }
  
  .lni-exit::before {
    content: "\eb03";
  }
  
  .lni-exit-up::before {
    content: "\eb04";
  }
  
  .lni-exit-down::before {
    content: "\eb05";
  }
  
  .lni-enter::before {
    content: "\eb06";
  }
  
  .lni-direction::before {
    content: "\eb07";
  }
  
  .lni-direction-rtl::before {
    content: "\eb08";
  }
  
  .lni-direction-ltr::before {
    content: "\eb09";
  }
  
  .lni-direction-alt::before {
    content: "\eb0a";
  }
  
  .lni-chevron-up::before {
    content: "\eb0b";
  }
  
  .lni-chevron-up-circle::before {
    content: "\eb0c";
  }
  
  .lni-chevron-right::before {
    content: "\eb0d";
  }
  
  .lni-chevron-right-circle::before {
    content: "\eb0e";
  }
  
  .lni-chevron-left::before {
    content: "\eb0f";
  }
  
  .lni-chevron-left-circle::before {
    content: "\eb10";
  }
  
  .lni-chevron-down::before {
    content: "\eb11";
  }
  
  .lni-chevron-down-circle::before {
    content: "\eb12";
  }
  
  .lni-arrows-vertical::before {
    content: "\eb13";
  }
  
  .lni-arrows-horizontal::before {
    content: "\eb14";
  }
  
  .lni-arrow-up::before {
    content: "\eb15";
  }
  
  .lni-arrow-up-circle::before {
    content: "\eb16";
  }
  
  .lni-arrow-top-right::before {
    content: "\eb17";
  }
  
  .lni-arrow-top-left::before {
    content: "\eb18";
  }
  
  .lni-arrow-right::before {
    content: "\eb19";
  }
  
  .lni-arrow-right-circle::before {
    content: "\eb1a";
  }
  
  .lni-arrow-left::before {
    content: "\eb1b";
  }
  
  .lni-arrow-left-circle::before {
    content: "\eb1c";
  }
  
  .lni-arrow-down::before {
    content: "\eb1d";
  }
  
  .lni-arrow-down-circle::before {
    content: "\eb1e";
  }
  
  .lni-angle-double-up::before {
    content: "\eb1f";
  }
  
  .lni-angle-double-right::before {
    content: "\eb20";
  }
  
  .lni-angle-double-left::before {
    content: "\eb21";
  }
  
  .lni-angle-double-down::before {
    content: "\eb22";
  }
  
  .lni-vector::before {
    content: "\eb23";
  }
  
  .lni-ux::before {
    content: "\eb24";
  }
  
  .lni-slice::before {
    content: "\eb25";
  }
  
  .lni-select-cursor::before {
    content: "\eb26";
  }
  
  .lni-pallet::before {
    content: "\eb27";
  }
  
  .lni-move::before {
    content: "\eb28";
  }
  
  .lni-layout::before {
    content: "\eb29";
  }
  
  .lni-layers::before {
    content: "\eb2a";
  }
  
  .lni-highlight::before {
    content: "\eb2b";
  }
  
  .lni-highlight-alt::before {
    content: "\eb2c";
  }
  
  .lni-grid::before {
    content: "\eb2d";
  }
  
  .lni-grid-alt::before {
    content: "\eb2e";
  }
  
  .lni-crop::before {
    content: "\eb2f";
  }
  
  .lni-brush::before {
    content: "\eb30";
  }
  
  .lni-brush-alt::before {
    content: "\eb31";
  }
  
  .lni-yen::before {
    content: "\eb32";
  }
  
  .lni-wallet::before {
    content: "\eb33";
  }
  
  .lni-rupee::before {
    content: "\eb34";
  }
  
  .lni-pound::before {
    content: "\eb35";
  }
  
  .lni-money-protection::before {
    content: "\eb36";
  }
  
  .lni-money-location::before {
    content: "\eb37";
  }
  
  .lni-euro::before {
    content: "\eb38";
  }
  
  .lni-dollar::before {
    content: "\eb39";
  }
  
  .lni-diamond-shape::before {
    content: "\eb3a";
  }
  
  .lni-diamond-alt::before {
    content: "\eb3b";
  }
  
  .lni-credit-cards::before {
    content: "\eb3c";
  }
  
  .lni-coin::before {
    content: "\eb3d";
  }
  
  .lni-calculator::before {
    content: "\eb3e";
  }
  
  .lni-calculator-alt::before {
    content: "\eb3f";
  }
  
  .lni-wheelbarrow::before {
    content: "\eb40";
  }
  
  .lni-trowel::before {
    content: "\eb41";
  }
  
  .lni-shovel::before {
    content: "\eb42";
  }
  
  .lni-ruler::before {
    content: "\eb43";
  }
  
  .lni-ruler-pencil::before {
    content: "\eb44";
  }
  
  .lni-ruler-alt::before {
    content: "\eb45";
  }
  
  .lni-paint-roller::before {
    content: "\eb46";
  }
  
  .lni-paint-bucket-alt::before {
    content: "\eb47";
  }
  
  .lni-helmet::before {
    content: "\eb48";
  }
  
  .lni-hammer::before {
    content: "\eb49";
  }
  
  .lni-construction::before {
    content: "\eb4a";
  }
  
  .lni-construction-hammer::before {
    content: "\eb4b";
  }
  
  .lni-bricks::before {
    content: "\eb4c";
  }
  
  .lni-train::before {
    content: "\eb4d";
  }
  
  .lni-train-alt::before {
    content: "\eb4e";
  }
  
  .lni-taxi::before {
    content: "\eb4f";
  }
  
  .lni-support::before {
    content: "\eb50";
  }
  
  .lni-ship::before {
    content: "\eb51";
  }
  
  .lni-scooter::before {
    content: "\eb52";
  }
  
  .lni-road::before {
    content: "\eb53";
  }
  
  .lni-reply::before {
    content: "\eb54";
  }
  
  .lni-postcard::before {
    content: "\eb55";
  }
  
  .lni-popup::before {
    content: "\eb56";
  }
  
  .lni-plane::before {
    content: "\eb57";
  }
  
  .lni-phone::before {
    content: "\eb58";
  }
  
  .lni-phone-set::before {
    content: "\eb59";
  }
  
  .lni-inbox::before {
    content: "\eb5a";
  }
  
  .lni-helicopter::before {
    content: "\eb5b";
  }
  
  .lni-envelope::before {
    content: "\eb5c";
  }
  
  .lni-comments::before {
    content: "\eb5d";
  }
  
  .lni-comments-reply::before {
    content: "\eb5e";
  }
  
  .lni-comments-alt-2::before {
    content: "\eb5f";
  }
  
  .lni-caravan::before {
    content: "\eb60";
  }
  
  .lni-car::before {
    content: "\eb61";
  }
  
  .lni-car-alt::before {
    content: "\eb62";
  }
  
  .lni-bus::before {
    content: "\eb63";
  }
  
  .lni-bullhorn::before {
    content: "\eb64";
  }
  
  .lni-bubble::before {
    content: "\eb65";
  }
  
  .lni-bridge::before {
    content: "\eb66";
  }
  
  .lni-bi-cycle::before {
    content: "\eb67";
  }
  
  .lni-anchor::before {
    content: "\eb68";
  }
  
  .lni-target::before {
    content: "\eb69";
  }
  
  .lni-target-revenue::before {
    content: "\eb6a";
  }
  
  .lni-tag::before {
    content: "\eb6b";
  }
  
  .lni-stats-up::before {
    content: "\eb6c";
  }
  
  .lni-stats-down::before {
    content: "\eb6d";
  }
  
  .lni-stamp::before {
    content: "\eb6e";
  }
  
  .lni-revenue::before {
    content: "\eb6f";
  }
  
  .lni-quotation::before {
    content: "\eb70";
  }
  
  .lni-pin::before {
    content: "\eb71";
  }
  
  .lni-pie-chart::before {
    content: "\eb72";
  }
  
  .lni-paperclip::before {
    content: "\eb73";
  }
  
  .lni-offer::before {
    content: "\eb74";
  }
  
  .lni-notepad::before {
    content: "\eb75";
  }
  
  .lni-license::before {
    content: "\eb76";
  }
  
  .lni-investment::before {
    content: "\eb77";
  }
  
  .lni-invest-monitor::before {
    content: "\eb78";
  }
  
  .lni-handshake::before {
    content: "\eb79";
  }
  
  .lni-grow::before {
    content: "\eb7a";
  }
  
  .lni-delivery::before {
    content: "\eb7b";
  }
  
  .lni-customer::before {
    content: "\eb7c";
  }
  
  .lni-consulting::before {
    content: "\eb7d";
  }
  
  .lni-cart::before {
    content: "\eb7e";
  }
  
  .lni-cart-full::before {
    content: "\eb7f";
  }
  
  .lni-briefcase-alt::before {
    content: "\eb80";
  }
  
  .lni-apartment::before {
    content: "\eb81";
  }
  
  .lni-agenda::before {
    content: "\eb82";
  }
  
  .lni-zoom::before {
    content: "\eb83";
  }
  
  .lni-zapier::before {
    content: "\eb84";
  }
  
  .lni-youtube::before {
    content: "\eb85";
  }
  
  .lni-ycombinator::before {
    content: "\eb86";
  }
  
  .lni-yahoo::before {
    content: "\eb87";
  }
  
  .lni-xrp::before {
    content: "\eb88";
  }
  
  .lni-xbox::before {
    content: "\eb89";
  }
  
  .lni-wordpress::before {
    content: "\eb8a";
  }
  
  .lni-wordpress-fill::before {
    content: "\eb8b";
  }
  
  .lni-windows::before {
    content: "\eb8c";
  }
  
  .lni-whatsapp::before {
    content: "\eb8d";
  }
  
  .lni-wechat::before {
    content: "\eb8e";
  }
  
  .lni-webhooks::before {
    content: "\eb8f";
  }
  
  .lni-vuejs::before {
    content: "\eb90";
  }
  
  .lni-vs-code::before {
    content: "\eb91";
  }
  
  .lni-volkswagen::before {
    content: "\eb92";
  }
  
  .lni-vmware::before {
    content: "\eb93";
  }
  
  .lni-vk::before {
    content: "\eb94";
  }
  
  .lni-visa::before {
    content: "\eb95";
  }
  
  .lni-vimeo::before {
    content: "\eb96";
  }
  
  .lni-vercel::before {
    content: "\eb97";
  }
  
  .lni-unsplash::before {
    content: "\eb98";
  }
  
  .lni-ubuntu::before {
    content: "\eb99";
  }
  
  .lni-typescript::before {
    content: "\eb9a";
  }
  
  .lni-twitter::before {
    content: "\eb9b";
  }
  
  .lni-twitter-original::before {
    content: "\eb9c";
  }
  
  .lni-twitter-fill::before {
    content: "\eb9d";
  }
  
  .lni-twitch::before {
    content: "\eb9e";
  }
  
  .lni-tumblr::before {
    content: "\eb9f";
  }
  
  .lni-trello::before {
    content: "\eba0";
  }
  
  .lni-toyota::before {
    content: "\eba1";
  }
  
  .lni-tiktok::before {
    content: "\eba2";
  }
  
  .lni-tiktok-alt::before {
    content: "\eba3";
  }
  
  .lni-tesla::before {
    content: "\eba4";
  }
  
  .lni-telegram::before {
    content: "\eba5";
  }
  
  .lni-telegram-original::before {
    content: "\eba6";
  }
  
  .lni-tailwindcss::before {
    content: "\eba7";
  }
  
  .lni-swift::before {
    content: "\eba8";
  }
  
  .lni-svelte::before {
    content: "\eba9";
  }
  
  .lni-stumbleupon::before {
    content: "\ebaa";
  }
  
  .lni-stripe::before {
    content: "\ebab";
  }
  
  .lni-steam::before {
    content: "\ebac";
  }
  
  .lni-stackoverflow::before {
    content: "\ebad";
  }
  
  .lni-squarespace::before {
    content: "\ebae";
  }
  
  .lni-spotify::before {
    content: "\ebaf";
  }
  
  .lni-spotify-original::before {
    content: "\ebb0";
  }
  
  .lni-soundcloud::before {
    content: "\ebb1";
  }
  
  .lni-soundcloud-original::before {
    content: "\ebb2";
  }
  
  .lni-snapchat::before {
    content: "\ebb3";
  }
  
  .lni-slideshare::before {
    content: "\ebb4";
  }
  
  .lni-slack::before {
    content: "\ebb5";
  }
  
  .lni-slack-line::before {
    content: "\ebb6";
  }
  
  .lni-skype::before {
    content: "\ebb7";
  }
  
  .lni-sketch::before {
    content: "\ebb8";
  }
  
  .lni-signal-app::before {
    content: "\ebb9";
  }
  
  .lni-shopify::before {
    content: "\ebba";
  }
  
  .lni-safari::before {
    content: "\ebbb";
  }
  
  .lni-reddit::before {
    content: "\ebbc";
  }
  
  .lni-react::before {
    content: "\ebbd";
  }
  
  .lni-quora::before {
    content: "\ebbe";
  }
  
  .lni-python::before {
    content: "\ebbf";
  }
  
  .lni-producthunt::before {
    content: "\ebc0";
  }
  
  .lni-postman::before {
    content: "\ebc1";
  }
  
  .lni-postgresql::before {
    content: "\ebc2";
  }
  
  .lni-playstation::before {
    content: "\ebc3";
  }
  
  .lni-play-store::before {
    content: "\ebc4";
  }
  
  .lni-play-store-fill::before {
    content: "\ebc5";
  }
  
  .lni-play-store-alt::before {
    content: "\ebc6";
  }
  
  .lni-pinterest::before {
    content: "\ebc7";
  }
  
  .lni-php::before {
    content: "\ebc8";
  }
  
  .lni-paypal::before {
    content: "\ebc9";
  }
  
  .lni-paypal-original::before {
    content: "\ebca";
  }
  
  .lni-patreon::before {
    content: "\ebcb";
  }
  
  .lni-paddle::before {
    content: "\ebcc";
  }
  
  .lni-opera::before {
    content: "\ebcd";
  }
  
  .lni-oculus::before {
    content: "\ebce";
  }
  
  .lni-npm::before {
    content: "\ebcf";
  }
  
  .lni-notion::before {
    content: "\ebd0";
  }
  
  .lni-nodejs::before {
    content: "\ebd1";
  }
  
  .lni-nodejs-alt::before {
    content: "\ebd2";
  }
  
  .lni-nissan::before {
    content: "\ebd3";
  }
  
  .lni-nextjs::before {
    content: "\ebd4";
  }
  
  .lni-netlify::before {
    content: "\ebd5";
  }
  
  .lni-netflix::before {
    content: "\ebd6";
  }
  
  .lni-mysql::before {
    content: "\ebd7";
  }
  
  .lni-mongodb::before {
    content: "\ebd8";
  }
  
  .lni-microsoft::before {
    content: "\ebd9";
  }
  
  .lni-microsoft-teams::before {
    content: "\ebda";
  }
  
  .lni-microsoft-edge::before {
    content: "\ebdb";
  }
  
  .lni-meta::before {
    content: "\ebdc";
  }
  
  .lni-meta-2::before {
    content: "\ebdd";
  }
  
  .lni-meta-1::before {
    content: "\ebde";
  }
  
  .lni-mercedes::before {
    content: "\ebdf";
  }
  
  .lni-medium::before {
    content: "\ebe0";
  }
  
  .lni-medium-alt::before {
    content: "\ebe1";
  }
  
  .lni-mastercard::before {
    content: "\ebe2";
  }
  
  .lni-markdown::before {
    content: "\ebe3";
  }
  
  .lni-mailchimp::before {
    content: "\ebe4";
  }
  
  .lni-magento::before {
    content: "\ebe5";
  }
  
  .lni-linkedin::before {
    content: "\ebe6";
  }
  
  .lni-linkedin-original::before {
    content: "\ebe7";
  }
  
  .lni-lineicons::before {
    content: "\ebe8";
  }
  
  .lni-lineicons-symbol::before {
    content: "\ebe9";
  }
  
  .lni-lineicons-symbol-alt::before {
    content: "\ebea";
  }
  
  .lni-lineicons-symbol-alt-2::before {
    content: "\ebeb";
  }
  
  .lni-lineicons-alt::before {
    content: "\ebec";
  }
  
  .lni-line::before {
    content: "\ebed";
  }
  
  .lni-laravel::before {
    content: "\ebee";
  }
  
  .lni-kubernetes::before {
    content: "\ebef";
  }
  
  .lni-jsfiddle::before {
    content: "\ebf0";
  }
  
  .lni-joomla::before {
    content: "\ebf1";
  }
  
  .lni-joomla-original::before {
    content: "\ebf2";
  }
  
  .lni-jcb::before {
    content: "\ebf3";
  }
  
  .lni-javascript::before {
    content: "\ebf4";
  }
  
  .lni-java::before {
    content: "\ebf5";
  }
  
  .lni-jamstack::before {
    content: "\ebf6";
  }
  
  .lni-jaguar::before {
    content: "\ebf7";
  }
  
  .lni-ios::before {
    content: "\ebf8";
  }
  
  .lni-instagram::before {
    content: "\ebf9";
  }
  
  .lni-instagram-original::before {
    content: "\ebfa";
  }
  
  .lni-instagram-fill::before {
    content: "\ebfb";
  }
  
  .lni-imdb::before {
    content: "\ebfc";
  }
  
  .lni-html5::before {
    content: "\ebfd";
  }
  
  .lni-hacker-news::before {
    content: "\ebfe";
  }
  
  .lni-grammarly::before {
    content: "\ebff";
  }
  
  .lni-google::before {
    content: "\ec00";
  }
  
  .lni-google-wallet::before {
    content: "\ec01";
  }
  
  .lni-google-pay::before {
    content: "\ec02";
  }
  
  .lni-google-drive::before {
    content: "\ec03";
  }
  
  .lni-google-cloud::before {
    content: "\ec04";
  }
  
  .lni-google-meet::before {
    content: "\ec05";
  }
  
  .lni-goodreads::before {
    content: "\ec06";
  }
  
  .lni-go::before {
    content: "\ec07";
  }
  
  .lni-github::before {
    content: "\ec08";
  }
  
  .lni-github-original::before {
    content: "\ec09";
  }
  
  .lni-git::before {
    content: "\ec0a";
  }
  
  .lni-gatsby::before {
    content: "\ec0b";
  }
  
  .lni-ford::before {
    content: "\ec0c";
  }
  
  .lni-flutter::before {
    content: "\ec0d";
  }
  
  .lni-flickr::before {
    content: "\ec0e";
  }
  
  .lni-fitbit::before {
    content: "\ec0f";
  }
  
  .lni-firefox::before {
    content: "\ec10";
  }
  
  .lni-firefox-original::before {
    content: "\ec11";
  }
  
  .lni-firebase::before {
    content: "\ec12";
  }
  
  .lni-figma::before {
    content: "\ec13";
  }
  
  .lni-facetime::before {
    content: "\ec14";
  }
  
  .lni-facebook::before {
    content: "\ec15";
  }
  
  .lni-facebook-oval::before {
    content: "\ec16";
  }
  
  .lni-facebook-original::before {
    content: "\ec17";
  }
  
  .lni-facebook-messenger::before {
    content: "\ec18";
  }
  
  .lni-facebook-line::before {
    content: "\ec19";
  }
  
  .lni-facebook-fill::before {
    content: "\ec1a";
  }
  
  .lni-ethereum::before {
    content: "\ec1b";
  }
  
  .lni-envato::before {
    content: "\ec1c";
  }
  
  .lni-edge::before {
    content: "\ec1d";
  }
  
  .lni-drupal::before {
    content: "\ec1e";
  }
  
  .lni-drupal-original::before {
    content: "\ec1f";
  }
  
  .lni-dropbox::before {
    content: "\ec20";
  }
  
  .lni-dropbox-original::before {
    content: "\ec21";
  }
  
  .lni-dribbble::before {
    content: "\ec22";
  }
  
  .lni-docker::before {
    content: "\ec23";
  }
  
  .lni-discover::before {
    content: "\ec24";
  }
  
  .lni-discord::before {
    content: "\ec25";
  }
  
  .lni-discord-alt::before {
    content: "\ec26";
  }
  
  .lni-diners-club::before {
    content: "\ec27";
  }
  
  .lni-digitalocean::before {
    content: "\ec28";
  }
  
  .lni-dialogflow::before {
    content: "\ec29";
  }
  
  .lni-dev::before {
    content: "\ec2a";
  }
  
  .lni-css3::before {
    content: "\ec2b";
  }
  
  .lni-creative-commons::before {
    content: "\ec2c";
  }
  
  .lni-cpanel::before {
    content: "\ec2d";
  }
  
  .lni-coral::before {
    content: "\ec2e";
  }
  
  .lni-connectdevelop::before {
    content: "\ec2f";
  }
  
  .lni-coinbase::before {
    content: "\ec30";
  }
  
  .lni-codepen::before {
    content: "\ec31";
  }
  
  .lni-cloudflare::before {
    content: "\ec32";
  }
  
  .lni-cisco::before {
    content: "\ec33";
  }
  
  .lni-chromecast::before {
    content: "\ec34";
  }
  
  .lni-chrome::before {
    content: "\ec35";
  }
  
  .lni-cash-app::before {
    content: "\ec36";
  }
  
  .lni-btc::before {
    content: "\ec37";
  }
  
  .lni-brave::before {
    content: "\ec38";
  }
  
  .lni-bootstrap::before {
    content: "\ec39";
  }
  
  .lni-bootstrap-5::before {
    content: "\ec3a";
  }
  
  .lni-bmw::before {
    content: "\ec3b";
  }
  
  .lni-bluetooth-original::before {
    content: "\ec3c";
  }
  
  .lni-blogger::before {
    content: "\ec3d";
  }
  
  .lni-bittorrent::before {
    content: "\ec3e";
  }
  
  .lni-bitcoin::before {
    content: "\ec3f";
  }
  
  .lni-bitbucket::before {
    content: "\ec40";
  }
  
  .lni-behance::before {
    content: "\ec41";
  }
  
  .lni-behance-original::before {
    content: "\ec42";
  }
  
  .lni-azure::before {
    content: "\ec43";
  }
  
  .lni-aws::before {
    content: "\ec44";
  }
  
  .lni-audi::before {
    content: "\ec45";
  }
  
  .lni-atlassian::before {
    content: "\ec46";
  }
  
  .lni-apple-pay::before {
    content: "\ec47";
  }
  
  .lni-apple-music::before {
    content: "\ec48";
  }
  
  .lni-apple-brand::before {
    content: "\ec49";
  }
  
  .lni-app-store::before {
    content: "\ec4a";
  }
  
  .lni-angular::before {
    content: "\ec4b";
  }
  
  .lni-angellist::before {
    content: "\ec4c";
  }
  
  .lni-android::before {
    content: "\ec4d";
  }
  
  .lni-android-original::before {
    content: "\ec4e";
  }
  
  .lni-amex::before {
    content: "\ec4f";
  }
  
  .lni-amazon::before {
    content: "\ec50";
  }
  
  .lni-amazon-pay::before {
    content: "\ec51";
  }
  
  .lni-amazon-original::before {
    content: "\ec52";
  }
  
  .lni-airtable::before {
    content: "\ec53";
  }
  
  .lni-airbnb::before {
    content: "\ec54";
  }
  
  .lni-adobe::before {
    content: "\ec55";
  }
  
  .lni-500px::before {
    content: "\ec56";
  }