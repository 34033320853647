.deal-filter-section .card-header>*, .filter-title>*{
    display: table-cell;
    vertical-align: middle;
}
.deal-filter-section .card-header i{
    width: 17px;
    padding: 0px;
    color: var(--black);
    font-size: 14px;
}
.deal-filter-section .card-header, .filter-title{
    display: table;
    width: 100%;
    padding: .5rem 1rem;
    /* position: relative; */
}
.filter-title .icon{
    width: 30px;
}
.filter-title h4{
    font-size: 14px;
    font-weight: 500;
}
.filter-container{
    background: #fff;
    /* background: #e8f3ff; */
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 30px;
    color: #525050;
}
.filter-container .card:hover{
    /* box-shadow: 4px 4px 10px 0 rgb(50 50 50 / 25%); */
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 25%);
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    transition: all .3s ease-out;
}
.deal-filter-section .card{
    margin-bottom: 10px;
}
.deal-filter-section .card-header{
    background: #f0f8ff;
    cursor: pointer;
}
.deal-filter-section select{
    width: 100%;
    height: 25px;
}
.deal-filter-section select option{
    font-size: 16px;
}
.filter-scroll{
    /* position: relative; */
    max-height: 500px;
    overflow: auto;
}
.deal-filter-section .accordian-title{
    /* color: #4a4a4a; */
    color: var(--black);
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    margin-bottom: 0px;
}
.deal-filter-section .card-header button{
    padding: 0px;
    background: #f0f8ff;
    box-shadow: none;
    display: inline-flex;
}
.deal-filter-section button::after{
    background-image: none;
    width: 0px;
}
.deal-filter-section .accordion-body{
    padding: 0px;
}
.deal-filter-section .accordian-details p{
    /* color: #444343; */
    color: var(--black);
    line-height: 16px;
}
.deal-filter-section .accordian-details label{
    width: 100%;
    cursor: pointer;
}
.deal-filter-section .input-label{
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-width: calc(100% - 32px);
    display: inline-block;
}
.deal-filter-section input[type="checkbox"]{
    float: left;
    width: 15px;
    height: 15px;
    margin-right: 4px;
    border-radius: 3px;
    border: 1px solid #ffa500 !important;
    cursor: pointer;
}
.deal-btn-wrapper{
    /* background: #e5e5e5; */
    /* background: #d2342d1a; */
    width: 245px;
    /* border-radius: 2px; */
    height: 40px;
    /* line-height: 40px; */
    position: relative;
    /* border: 1.5px dashed #979797; */
    /* z-index: 1;
    color: #fff;
    display: block;
    text-align: center; */
    /* margin: 0 auto; */
    padding-left: 9px;
    padding-right: 9px;
    /* box-shadow: 0 2px 4px 0 rgb(210 52 45 / 60%); */
}
/* .deal-btn-wrapper .code{ */
    /* text-align: right; */
    /* color: #000; */
    /* font-weight: 700; */
    /* padding-right: 18px; */
/* } */
.deal-btn-wrapper .btn-wrapper{
    position: absolute;
    display: block;
    width: 100%;
    top: 9px;
}
/* .deal-btn-wrapper .button{
    transition: all .3s ease;
    height: 44px;
    padding: 12px 20px;
    font-size: 13px;
    font-weight: 700;
    width: 211px;
    color: #fff;
    border-radius: 4px 2px 2px 4px;
    border: 1px solid transparent;
    background: #66af77;
    border-color: #57bb63;
} */
.deal-btn-wrapper:hover > .btn-wrapper{
    top: 11px;
}
.deal-footer .info{
    color: #8e8c8c;
    font-size: 12px;
}
.deal-footer .show-more, .visit-link{
    color: #d2342d;
    font-weight: 700;
    cursor: pointer;
}
.deal-footer .show-more:hover, .visit-link:hover{
    font-size: 15px;
}
.filter-container h6{
    color: #555877;
}
/* .filter-container a */
.d-code{
    background: #d2342d1a;
    /* width: 245px; */
    border-radius: 2px;
    /* height: 40px; */
    /* position: relative; */
    border: 1.5px dashed #979797;
    z-index: 1;
    color: #fff;
    display: block;
    text-align: center;
    margin: 0 auto;
    box-shadow: 0 2px 4px 0 rgb(210 52 45 / 60%);
}
.coupon-code{
    width: fit-content;
    min-width: 200px;
}
.collapse-menu h2 button{
    padding: 8px 12px;
    color: var(--black) !important;
    font-size: 15px;
}