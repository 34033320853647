:root{
  /* --primary: #fe993bd4; */
  /* --primary: #d2342d; */
  --primary: #b2dded;
  /* --secondary: orange; */
  --secondary: #1B71A1;
  --primary-dark: #283779;
  /* --black: #D4AF37; */
  --black: #7f0909;
  --secondary-light: #ffa5006b;
  --primary-light: #a3cce3;
  --orange: #ffa500;
}
/*=========================================================
	01 -> GENERAL
===========================================================*/
::placeholder, :-moz-placeholder, ::-webkit-input-placeholder , ::-ms-input-placeholder{
  color: var(--black) !important;
  opacity: 1; /* Firefox */
}
input[type="checkbox"]{
  -moz-appearance:none;
  -webkit-appearance:none;
  -o-appearance:none;
  /* outline: 1px solid var(--black); */
  -webkit-box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
  -moz-box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
  box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
}
input[type=checkbox]:checked{
  /* background: var(--secondary); */
  background: var(--orange);
  outline: none;
  border: 1px solid var(--secondary);
}
input, textarea{
  /* border: 1px solid #E4E7ED !important; */
  border: 1px solid var(--secondary) !important;
  color: var(--black) !important;
}
/* input::placeholder{
  color: var(--black);
} */
select, option{
  /* background: #ffa5002e; */
  /* background: #ffa5001a; */
  outline: none;
  border: 1px solid #7f090921;
  color: var(--black);
}
button:disabled{
  pointer-events: auto !important;
}
/* option:hover {
  color: red;
  background-color: red;
} */
section{
  position: relative;
  /* padding-bottom: 50px; */
  margin-bottom: 50px;
}
.table{
  color: var(--black);
}
.material-symbols-sharp {
  /* font-variation-settings:
  'FILL' 0,
  'wght' 700,
  'GRAD' 0,
  'opsz' 40 */
  font-weight: 700;
}
strong {
  font-weight: 600;
}
.h-500{
  max-height: 500px;
}
/*----------------------------*\
	Typography
\*----------------------------*/

@media only screen and (max-width: 400px) {
  .product-details .add-to-cart .add-to-cart-btn{
    font-size: 12px;
    padding: 0 20px !important;
  }
  .header-search{
    font-size: 12px;
  }
  .header-search select,
  .header-search input,
  .header-search .search-btn{
    height: 35px !important;
    padding: 0px 5px;
    font-weight: 500;
    font-size: 14px;
  }
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  /* color: #333; */
  /* color: var(--black); */
  color: #0d4666;
  background-color: #ebedef;
  font-size: 14px;
  /* background-color: #ffa73c17; */
  /* background: #ebedef61; */
}

h1, h2, h3, h4, h5, h6 {
  /* color: #2B2D42; */
  /* color: var(--black); */
  color: var(--primary-dark);
  font-weight: 700;
  margin: 0 0 10px;
}

a {
  /* color: #2B2D42; */
  color: var(--black);
  font-weight: 500;
  -webkit-transition: 0.2s color;
  transition: 0.2s color;
  text-decoration: none !important;
}

a:hover, a:focus {
  color: #D10024;
  text-decoration: none;
  outline: none;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none
}

.display-i-flex{
  display: inline-flex;
}
/*----------------------------*\
	Buttons
\*----------------------------*/

.primary-btn {
  display: inline-block;
  padding: 12px 30px;
  background-color: #D10024;
  border: none;
  border-radius: 40px;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
.header-dropdown .primary-btn:hover,
.header-dropdown .primary-btn:focus,
.header-dropdown .btn-primary:hover,
.header-dropdown .btn-primary:focus{
  box-shadow: none !important;
}
.header-dropdown .btn-primary{
  background: none !important;
  border: none;
  color: var(--primary-dark);
}
.primary-btn:hover, .primary-btn:focus, .btn-primary:hover, .btn-primary:focus  {
  opacity: 0.9;
  color: #FFF;
  /* box-shadow: 0 0 0 .2rem rgb(209, 0, 36) !important; */
  box-shadow: 0 0 0 0.1rem rgb(207 55 121) !important;
}
/* .btn{
  padding: 5px 12px;
} */
.btn-primary, .btn-primary.disabled, .btn-primary:disabled, .btn-primary:hover, .btn-primary:focus, .btn-primary:not([disabled]):not(.disabled).active, .btn-primary:not([disabled]):not(.disabled):active, .show > .btn-primary.dropdown-toggle{
  /* background: #D10024 !important; */
  /* border-color: #D10024 !important; */
  /* background: var(--primary) !important;
  border-color: var(--primary) !important; */
  background: var(--secondary) !important;
  border-color: var(--secondary) !important;
  font-weight: 500;
}
button{
  cursor: pointer;
}
button:disabled{
  cursor: not-allowed;
}
/*----------------------------*\
	Inputs
\*----------------------------*/

/*-- Text input --*/

.input {
  height: 40px;
  padding: 0px 15px;
  border: 1px solid #E4E7ED;
  background-color: #FFF;
  width: 100%;
}
.input:focus-visible{
  outline: none;
}
textarea.input {
  padding: 15px;
  min-height: 90px;
}

/*-- Number input --*/

.input-number {
  position: relative;
}

.input-number input[type="number"]::-webkit-inner-spin-button, .input-number input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-number input[type="number"] {
  -moz-appearance: textfield;
  height: 40px;
  width: 100%;
  border: 1px solid #E4E7ED;
  background-color: #FFF;
  padding: 0px 35px 0px 15px;
}

.input-number .qty-up, .input-number .qty-down {
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #E4E7ED;
  background-color: #FFF;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input-number .qty-up {
  right: 0;
  top: 0;
  border-bottom: 0px;
}

.input-number .qty-down {
  right: 0;
  bottom: 0;
}

.input-number .qty-up:hover, .input-number .qty-down:hover {
  background-color: #E4E7ED;
  color: #D10024;
}

/*-- Select input --*/

.input-select {
  padding: 0px 15px;
  background: #FFF;
  border: 1px solid #E4E7ED;
  height: 40px;
  color: var(--black);
}
input, .category-title{
  color: var(--black);
}
/*-- checkbox & radio input --*/

.input-radio, .input-checkbox {
  position: relative;
  display: block;
}

.input-radio input[type="radio"]:not(:checked), .input-radio input[type="radio"]:checked, .input-checkbox input[type="checkbox"]:not(:checked), .input-checkbox input[type="checkbox"]:checked {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}

.input-radio label, .input-checkbox label {
  font-weight: 500;
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 5px;
  cursor: pointer;
}

.input-radio input[type="radio"]+label span, .input-checkbox input[type="checkbox"]+label span {
  position: absolute;
  left: 0px;
  top: 4px;
  width: 14px;
  height: 14px;
  border: 2px solid #E4E7ED;
  background: #FFF;
}

.input-radio input[type="radio"]+label span {
  border-radius: 50%;
}

.input-radio input[type="radio"]+label span:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  background-color: #FFF;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  opacity: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.input-checkbox input[type="checkbox"]+label span:after {
  content: '✔';
  position: absolute;
  top: -2px;
  left: 1px;
  font-size: 10px;
  color: #FFF;
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.input-radio input[type="radio"]:checked+label span, .input-checkbox input[type="checkbox"]:checked+label span {
  background-color: #D10024;
  border-color: #D10024;
}

.input-radio input[type="radio"]:checked+label span:after {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}

.input-checkbox input[type="checkbox"]:checked+label span:after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.input-radio .caption, .input-checkbox .caption {
  margin-top: 5px;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: 0.3s max-height;
  transition: 0.3s max-height;
}

.input-radio input[type="radio"]:checked~.caption, .input-checkbox input[type="checkbox"]:checked~.caption {
  max-height: 800px;
}

/*----------------------------*\
	Section
\*----------------------------*/

.section {
  padding-top: 30px;
  padding-bottom: 30px;
}

.section-title {
  position: relative;
  margin-bottom: 30px;
  margin-top: 15px;
}

.section-title .title {
  display: inline-block;
  text-transform: uppercase;
  margin: 0px;
}

.section-title .section-nav {
  float: right;
}

.section-title .section-nav .section-tab-nav {
  display: inline-block;
}

.section-tab-nav li {
  display: inline-block;
  margin-right: 15px;
}

.section-tab-nav li:last-child {
  margin-right: 0px;
}

.section-tab-nav li a {
  font-weight: 700;
  color: #8D99AE;
}

.section-tab-nav li a:after {
  content: "";
  display: block;
  width: 0%;
  height: 2px;
  background-color: #D10024;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.section-tab-nav li.active a {
  color: #D10024;
}

.section-tab-nav li a:hover:after, .section-tab-nav li a:focus:after, .section-tab-nav li.active a:after {
  width: 100%;
}

.section-title .section-nav .products-slick-nav {
  top: 0px;
  right: 0px;
}

/*----------------------------*\
	Breadcrumb
\*----------------------------*/

#breadcrumb {
  padding: 30px 0px;
  background: #FBFBFC;
  border-bottom: 1px solid #E4E7ED;
  margin-bottom: 30px;
}

#breadcrumb .breadcrumb-header {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 15px;
  text-transform: uppercase;
}

#breadcrumb .breadcrumb-tree {
  display: inline-block;
}

#breadcrumb .breadcrumb-tree li {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

#breadcrumb .breadcrumb-tree li+li {
  margin-left: 10px;
}

#breadcrumb .breadcrumb-tree li+li:before {
  content: '/';
  display: inline-block;
  color: #8D99AE;
  margin-right: 10px;
}

#breadcrumb .breadcrumb-tree li a {
  color: #8D99AE;
}

#breadcrumb .breadcrumb-tree li a:hover {
  color: #D10024;
}

/*=========================================================
	02 -> HEADER
===========================================================*/

/*----------------------------*\
	Top header
\*----------------------------*/

#top-header {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #1E1F29;
}

.header-links li {
  display: inline-block;
  margin-right: 15px;
  font-size: 12px;
}

.header-links li:last-child {
  margin-right: 0px;
}

.header-links li a {
  color: #FFF;
}

.header-links li a:hover {
  color: #D10024;
}

.header-links li i {
  color: #D10024;
  margin-right: 5px;
}

/*----------------------------*\
	Logo/Header
\*----------------------------*/
.top-nav{
  background: var(--secondary);
  padding: 8px 0px;
}
.top-nav p{
  display: flex;
  margin-bottom: 0px;
  justify-content: center;
  align-items: center;
  color: white;
}
.top-nav .btn-whatsapp-pulse {
	background: #25d366;
	color: white;
	/* position: fixed; */
	/* bottom: 20px; */
	/* right: 20px; */
	font-size: 21px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 0;
	height: 0;
	padding: 16px;
	text-decoration: none;
	border-radius: 50%;
	/* animation-name: pulse; */
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
  margin-right: 5px;
}
.top-nav .btn-whatsapp-pulse-border{
	/* bottom: 120px; */
	/* right: 20px; */
	animation-play-state: paused;
}
.top-nav .btn-whatsapp-pulse-border::before {
	content: "";
	position: absolute;
	border-radius: 50%;
	padding: 25px;
	border: 5px solid #25d366;
	opacity: 0.75;
	animation-name: pulse-border;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}
/* @keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
	}
	80% {
		box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
	}
} */
@-webkit-keyframes pulse-border {
  0% {
		padding: 10px;
		opacity: 0.75;
	}
	75% {
		padding: 25px;
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@keyframes pulse-border {
	0% {
		padding: 10px;
		opacity: 0.75;
	}
	75% {
		padding: 25px;
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
.top-nav a{
  display: table;
  margin: 0px auto;
}
.top-nav a:hover p{
  /* color: var(--primary); */
  color: #25d366;
}
#header {
  padding-top: 10px;
  padding-bottom: 4px;
  /* background-color: #15161D; */
  background-color: var(--primary);
  z-index: 9999;
  display: block;
}
#header.header-scrolled {
  position: fixed;
  width: 100%;
  top: 0;
}
.header-logo {
  float: left;
}

.header-logo .logo img {
  display: block;
  width:44px;
  height: 44px;
  /* -webkit-transform: rotate(9deg);
  -ms-transform: rotate(9deg);
  transform: rotate(9deg); */
  border-radius: 50%;
}
.header-logo .logo{
  display: inline-flex;
  font-weight: 700;
  /* color: #fff; */
  /* color: yellow; */
  color: var(--secondary);
  font-size: 13px;
}
.header-logo .logo p{
  color: white;
  font-size: 28px;
  font-weight: 600;
  -webkit-transform: rotate(9deg);
  -ms-transform: rotate(9deg);
  transform: rotate(9deg);
  /* font-family: monospace; */
}

/*----------------------------*\
	Search
\*----------------------------*/

.header-search {
  padding: 6px 0px;
}
.header-search form {
  position: relative;
  display: flex;
}
.header-search .search-box{
  display: contents;
}
.header-search form .input-select {
  /* margin-right: -4px; */
  margin-right: -1px;
  border-radius: 40px 0px 0px 40px;
  border: 1px solid var(--secondary);
  border-right: 1px solid #E4E7ED;
}


.header-search form .input {
  width: calc(100% - 235px);
  /* margin-right: -4px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px; */
}

.header-search .search-btn {
  height: 40px;
  width: 100px;
  /* background: #D10024; */
  background: var(--secondary);
  color: #FFF;
  font-weight: 700;
  border: none;
  border-radius: 0px 40px 40px 0px;
}

.footer-search {
  padding: 15px 0px;
}

.footer-search form {
  position: relative;
}

.footer-search form .input-select {
  margin-right: -4px;
  border-radius: 40px 0px 0px 40px;
}

.footer-search form .input {
  width: calc(100% - 260px);
  margin-right: -4px;
  border-radius: 40px 0px 0px 40px;
}

.footer-search form .search-btn {
  height: 40px;
  width: 100px;
  background: #D10024;
  color: #FFF;
  font-weight: 700;
  border: none;
  border-radius: 0px 40px 40px 0px;
}

/*----------------------------*\
	Cart
\*----------------------------*/
.header-ctn .btn-success{
  font-size: 14px;
  font-weight: 500;
  padding: 7px;
  /* background: linear-gradient(90deg, rgb(207 55 121) 0%, rgb(41 108 186) 100%); */
  background: linear-gradient(90deg, rgb(41 108 186) 0%, rgb(207 55 121) 100%);
}
.header-ctn-wrapper{
  align-items: center;
  display: flex;
  /* float: right; */
}
.header-ctn {
  /* float: right; */
  /* padding: 15px 0px 0px; */
  padding: 6px 0px 0px;
  text-align: center;
  align-items: center;
}

.header-ctn>div {
  display: inline-block;
}

.header-ctn>div+div {
  margin-left: 6px;
}

.header-ctn>div>a {
  display: block;
  position: relative;
  /* width: 90px; */
  text-align: center;
  /* color: #FFF; */
  margin: 0px 10px;
  color: var(--primary-dark);
}

.header-ctn>div>a>i,
.header-dropdown .menu-toggle button i{
  display: block;
  font-size: 18px;
}
.header-ctn .btn-success i{
  font-size: 14px;
}

.header-ctn>div>a>span, .header-dropdown .menu-toggle button span {
  font-size: 12px;
}

.header-ctn>div>a>.qty {
  position: absolute;
  /* right: 15px; */
  right: 2px;
  top: -10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 10px;
  color: #FFF;
  /* background-color: var(--secondary); */
  background-color: #ff6f61;
}

.header-dropdown .dropdown-toggle::after{
  display: none;
}

.header-dropdown .menu-toggle button{
  padding: 0px;
}
.header-dropdown{
  display: inline-flex;
}

.header-dropdown .menu-toggle button span{
  margin-top: 1px;
}
/* .menu-toggle a{
  display: none;
  color: #fff;
} */

.dropdown-item:focus, .dropdown-item:hover{
  background-color: #fff6e5;
}

.cart-dropdown {
  position: absolute;
  width: 300px;
  background: #FFF;
  padding: 15px;
  -webkit-box-shadow: 0px 0px 0px 2px #E4E7ED;
  box-shadow: 0px 0px 0px 2px #E4E7ED;
  z-index: 99;
  right: 0;
  opacity: 0;
  visibility: hidden;
}

.dropdown.open>.cart-dropdown {
  opacity: 1;
  visibility: visible;
}
.dropdown-item{
  color: var(--black);
}

.cart-dropdown .cart-list {
  max-height: 180px;
  overflow-y: scroll;
  margin-bottom: 15px;
}

.cart-dropdown .cart-list .product-widget {
  padding: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.cart-dropdown .cart-list .product-widget:last-child {
  margin-bottom: 0px;
}

.cart-dropdown .cart-list .product-widget .product-img {
  left: 0px;
  top: 0px;
}

.cart-dropdown .cart-list .product-widget .product-body .product-price {
  color: #2B2D42;
}

.cart-dropdown .cart-btns {
  margin: 0px -17px -17px;
}

.cart-dropdown .cart-btns>a {
  display: inline-block;
  width: calc(50% - 0px);
  padding: 12px;
  background-color: #D10024;
  color: #FFF;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.cart-dropdown .cart-btns>a:first-child {
  margin-right: -4px;
  background-color: #1e1f29;
}

.cart-dropdown .cart-btns>a:hover {
  opacity: 0.9;
}

.cart-dropdown .cart-summary {
  border-top: 1px solid #E4E7ED;
  padding-top: 15px;
  padding-bottom: 15px;
}
/*=========================================================
Nav Animated button
===========================================================*/
@-webkit-keyframes btn-glow {
  0% {
    box-shadow: 0 0 0 0 #dc143c;
  }
  50% {
    box-shadow: 0 0 30px 0 #dc143c;
  }
}

@keyframes btn-glow {
  0% {
    box-shadow: 0 0 0 0 #dc143c;
  }
  50% {
    box-shadow: 0 0 10px 0 #dc143c;
  }
}
.btn-glow {
  /* box-shadow: 0 0 0 0 #dc143c; */
  -webkit-animation: btn-glow 2s linear infinite;
          animation: btn-glow 2s linear infinite;
}
/* .nav-button-ani{
  position: relative;
  width: 200px;
  height: 200px;
  margin: auto;
  background: url("//blog.codepen.io/wp-content/uploads/2012/06/Button-White-Large.png") no-repeat 50%/70% rgba(0, 0, 0, 0.1);
  color: #69ca62;
  box-shadow: inset 0 0 0 1px rgba(105, 202, 98, 0.5);
}
.nav-button-ani::before, .nav-button-ani::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.nav-button-ani::before, .nav-button-ani::after {
  color: #69ca62;
  content: "";
  z-index: 1;
  margin: -5%;
  box-shadow: inset 0 0 0 2px;
  animation: squareClipMe 8s linear infinite;
}
.nav-button-ani::before {
  animation-delay: -4s;
}
.nav-button-ani:hover::after, .nav-button-ani:hover::before {
  background-color: rgba(255, 0, 0, 0.3);
}

@keyframes squareClipMe {
  0%, 100% {
    clip: rect(0px, 220px, 2px, 0px);
  }
  25% {
    clip: rect(0px, 2px, 220px, 0px);
  }
  50% {
    clip: rect(218px, 220px, 220px, 0px);
  }
  75% {
    clip: rect(0px, 220px, 220px, 218px);
  }
} */
/*=========================================================
	03 -> Navigation
===========================================================*/

#navigation {
  background: #FFF;
  border-bottom: 2px solid #E4E7ED;
  border-top: 3px solid #D10024;
}

/*----------------------------*\
	Main nav
\*----------------------------*/
.main-nav{
  justify-content: center;
}
.main-nav>li+li {
  margin-left: 30px
}

.main-nav>li {
  /* padding: 12px 0px; */
  padding: 8px 0px 0px;
  text-align: center;
}

.main-nav>li>a:hover, .main-nav>li>a:focus, .main-nav>li.active>a {
  color: #D10024;
  background-color: transparent;
}

.main-nav>li>a:after {
  content: "";
  display: block;
  width: 0%;
  height: 2px;
  background-color: #D10024;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.main-nav>li>a:hover:after, .main-nav>li>a:focus:after, .main-nav>li.active>a:after {
  width: 100%;
}

.header-ctn li.nav-toggle {
  display: none;
}

/*----------------------------*\
	responsive nav
\*----------------------------*/

@media only screen and (max-width: 991px) {
  .header-search form .input{
    width: calc(100% - 120px);
  }
  .main-nav>li+li {
    margin-left: 10px;
  }
  .banner img, .banner-section .slick-track,  .banner-wrapper, .banner{
    height: 200px !important;
  }
  .banner-main .btn-glow{
    background: linear-gradient(90deg, rgb(41 108 186) 0%, rgb(207 55 121) 100%) !important;
    margin-bottom: 30px;
  }
  /* .header-ctn .menu-toggle {
    display: inline-block;
  } */
  /* #responsive-nav {
    position: fixed;
    left: 0;
    top: 0;
    background: #15161D;
    height: 100vh;
    max-width: 250px;
    width: 0%;
    overflow: hidden;
    z-index: 22;
    padding-top: 60px;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
  } */
  /* #responsive-nav.active {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
    width: 100%;
  } */
  /* .main-nav {
    margin: 0px;
    float: none;
  }
  .main-nav>li {
    display: block;
    float: none;
  }
  .main-nav>li+li {
    margin-left: 0px;
  }
  .main-nav>li>a {
    padding: 15px;
    color: #FFF;
  } */
}
/* @media only screen and (max-width: 1400px){
  .header-search form{
    margin: 0px auto;
  }
} */

/*=========================================================
	04 -> CATEGORY SHOP
===========================================================*/

.shop {
  position: relative;
  overflow: hidden;
  margin: 15px 0px;
}

.shop:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px;
  width: 60%;
  background: #D10024;
  opacity: 0.9;
  -webkit-transform: skewX(-45deg);
  -ms-transform: skewX(-45deg);
  transform: skewX(-45deg);
}

.shop:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1px;
  width: 100%;
  background: #D10024;
  opacity: 0.9;
  -webkit-transform: skewX(-45deg) translateX(-100%);
  -ms-transform: skewX(-45deg) translateX(-100%);
  transform: skewX(-45deg) translateX(-100%);
}

.shop .shop-img {
  position: relative;
  background-color: #E4E7ED;
  z-index: -1;
}

.shop .shop-img>img {
  width: 100%;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.shop:hover .shop-img>img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.shop .shop-body {
  position: absolute;
  top: 0;
  width: 75%;
  padding: 30px;
  z-index: 10;
}

.shop .shop-body h3 {
  color: #FFF;
}

.shop .shop-body .cta-btn {
  color: #FFF;
  text-transform: uppercase;
}


/*=========================================================
	05 -> HOT DEAL
===========================================================*/

/* #hot-deal.section {
  padding: 60px 0px;
  margin: 30px 0px;
  background-color: #E4E7ED;
  background-image: url('../img/hotdeal.png');
  background-position: center;
  background-repeat: no-repeat;
}

.hot-deal {
  text-align: center;
}

.hot-deal .hot-deal-countdown {
  margin-bottom: 30px;
}

.hot-deal .hot-deal-countdown>li {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  background: #D10024e6;
  text-align: center;
  border-radius: 50%;
  margin: 0px 5px;
}

.hot-deal .hot-deal-countdown>li>div {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.hot-deal .hot-deal-countdown>li>div h3 {
  color: #FFF;
  margin-bottom: 0px;
}

.hot-deal .hot-deal-countdown>li>div span {
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  color: #FFF;
}

.hot-deal p {
  text-transform: uppercase;
  font-size: 24px;
}

.hot-deal .cta-btn {
  margin-top: 15px;
} */

/*=========================================================
	06 -> PRODUCT
===========================================================*/

/*----------------------------*\
	product
\*----------------------------*/

.product {
  position: relative;
  margin: 15px 0px;
  /* -webkit-box-shadow: 0px 0px 0px 0px #E4E7ED, 0px 0px 0px 1px #E4E7ED; */
  /* box-shadow: 0px 0px 0px 0px #E4E7ED, 0px 0px 0px 1px #E4E7ED; */
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  -webkit-box-shadow: 0 2px 9px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 9px 0 rgba(0,0,0,.12);
  border: 1px dotted #ffa73c;
  /* z-index: 1; */
}
.product:hover{
  /* box-shadow: 0 2px 9px 0 rgba(0,0,0,.12); */
  -webkit-box-shadow: 0 2px 9px 0 #7f1c1794;
  box-shadow: 0 2px 9px 0 #7f1c1794;
}
/* .product:hover {
  -webkit-box-shadow: 0px 0px 6px 0px #E4E7ED, 0px 0px 0px 2px #D10024;
  box-shadow: 0px 0px 6px 0px #E4E7ED, 0px 0px 0px 2px #D10024;
} */

.product .product-img {
  position: relative;
  text-align: center;
  height: 125px;
  /* height: 200px; */
}

.product .product-img>img {
  /* width: 100%; */
  /* height: 135px; */
  height: 100%;
  border-radius: 5px;
  /* margin-top: 10px; */
  max-width: 100%;
  padding: 0px 12px;
}

.product .product-img .product-label {
  position: absolute;
  top: 5px;
  left: 5px;
}

.product .product-label>span,
.product .product-img .product-label>span {
  border: 2px solid;
  padding: 2px;
  font-size: 12px;
}

.product .product-label>span.sale,
.product .product-img .product-label>span.sale {
  /* background-color: #FFF; */
  /* border-color: #D10024; */
  /* color: #D10024; */
  border-radius: 3px;
  border: 1px dashed #1aab2a;
  color: #1aab2a;
  background-color: rgba(26,171,42,.05);
}

.product .product-img .product-label>span.new {
  background-color: #D10024;
  border-color: #D10024;
  color: #FFF;
}

.product .product-body {
  position: relative;
  padding: 8px;
  background-color: #FFF;
  text-align: center;
  /* z-index: 20; */
}

.product .product-body .product-category {
  text-transform: uppercase;
  /* font-size: 12px; */
  /* color: #8D99AE; */
  font-size: 10px;
  color: #801f1a75;
  font-weight: 600;
}

.product .product-body .product-name {
  text-transform: uppercase;
  font-size: 13px;
  height: 35px;
  color: var(--black);
}

.product-test .product-body .product-name{
  height: auto;
}
.product-test .product-body img{
  height: 40px;
}
.product .product-body .product-name>a {
  /* font-weight: 700; */
  font-weight: 500;
  overflow: hidden;
  /* width: calc(100% - 65px); */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.product .product-body .product-name>a:hover, .product .product-body .product-name>a:focus {
  color: #D10024;
}

/* .product .product-body .product-price { */
.product-price {
  color: #D10024;
  font-size: 16px;
  /* padding-top: 18px; */
  padding-top: 10px;
}
.product-price span{
  font-size: 12px;
  margin-left: 5px;
}
/* .product .product-body .product-price .product-old-price { */
.product-old-price {
  font-size: 70%;
  font-weight: 400;
  /* color: #8D99AE; */
  color: #7f1c17cf;
}
.product .product-body .measure{
  margin-top: 35px;
}
.product .product-body .measure select{
  width: 100%;
}
.product .product-body .product-rating {
  position: relative;
  margin: 15px 0px 10px;
  height: 20px;
}

.product .product-body .product-rating>i {
  position: relative;
  width: 14px;
  margin-right: -4px;
  background: #FFF;
  color: #E4E7ED;
  z-index: 10;
}

.product .product-body .product-rating>i.fa-star {
  color: #ef233c;
}

.product .product-body .product-rating:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 1px;
  background-color: #E4E7ED;
}

.product .product-body .product-btns>button {
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: transparent;
  border: none;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.product .product-body .product-btns>button:hover {
  background-color: #E4E7ED;
  color: #D10024;
  border-radius: 50%;
}

.product .product-body .product-btns>button .tooltipp {
  position: absolute;
  bottom: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, -15px);
  -ms-transform: translate(-50%, -15px);
  transform: translate(-50%, -15px);
  width: 150px;
  padding: 10px;
  font-size: 12px;
  line-height: 10px;
  background: #1e1f29;
  color: #FFF;
  text-transform: uppercase;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.product .product-body .product-btns>button:hover .tooltipp {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-50%, -5px);
  -ms-transform: translate(-50%, -5px);
  transform: translate(-50%, -5px);
}

.product .add-to-cart,
.cart-listing .add-to-cart{
  /* position: absolute;
  left: 1px;
  right: 1px;
  bottom: 1px; */
  padding: 0px 8px 15px;
  /* background: #1e1f29; */
  text-align: center;
  /* -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%); */
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  /* z-index: 2; */
}
.product .small-add-to-cart{
  width: 175px;
  padding: 0px;
  /* float: left; */
  text-align: left;
}
/* .product:hover .add-to-cart {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
} */

.product .add-to-cart .add-to-cart-btn,
.cart-listing .add-to-cart .add-to-cart-btn{
  position: relative;
  border: 2px solid var(--secondary);
  height: 30px;
  padding: 0 15px;
  /* background-color: #ef233c; */
  /* background-color: var(--secondary); */
  background: white;
  /* color: #FFF; */
  color: var(--secondary);
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 40px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  font-size: 12px;
  /* min-width: 85%; */
  min-width: 120px;
  /* padding-right: 35px; */
}

.product .add-to-cart .add-to-cart-btn>i {
  /* position: absolute; */
  left: 0;
  top: 0;
  /* width: 40px; */
  /* height: 40px; */
  line-height: 30px;
  color: #D10024;
  /* opacity: 0; */
  /* visibility: hidden; */
}

.product .add-to-cart .add-to-cart-btn:hover {
  background-color: #FFF;
  color: #D10024;
  border-color: #D10024;
  /* padding: 0px 30px 0px 50px; */
}

.product .add-to-cart .add-to-cart-btn:hover>i {
  opacity: 1;
  visibility: visible;
}
/* .add-to-cart .select-wrapper{
  border-radius:36px;
  display:inline-block;
  overflow:hidden;
  border:2px solid var(--secondary);
  padding: 0px 12px;
}
.add-to-cart .select-wrapper select{
  height:25px;
  border:0px;
  outline:none;
  color: var(--secondary);
  font-weight: 700;
  font-size: 13px;
} */
/* .add-to-cart .select-wrapper {
  position: relative;
  border: 1px solid black;
  border-radius: 2px;
  padding: 5px;
}
.add-to-cart .select-wrapper::after {
  content: "▼";
  font-size: 1rem;
  right: 10px;
  position: absolute;
} */
/* .product .add-to-cart .add-to-cart-btn .qty{
  background: #fff;
  color: #7f1c17;
} */
/* .add-to-cart-custom{
  display: inline-flex;
  border: 2px solid transparent;
  height: 30px;
  padding: 3px 5px;
  background: #ffa500ab;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 40px;
  transition: 0.2s all;
  font-size: 12px;
  min-width: 85%;
}
.add-to-cart-custom .cart-btn-wrap{
  width: 100%;
}
.add-to-cart-custom .cart-btn-wrap .qty{
  line-height: 20px;
  background: #fff;
  color: #7f1c17;
  width: calc(100% - 70px);
  border-radius: 2px;
}
.add-to-cart-custom .cart-btn-wrap .qty span{
  margin: 0px auto;
  padding: 0px 5px;
}
.add-to-cart-custom .cart-btn-wrap p{
  margin-bottom: 0px;
  display: inline-flex;
}
.add-to-cart-custom .cart-btn-wrap .plus{
  float: left;
}
.add-to-cart-custom .cart-btn-wrap .minus{
  float: right;
}
.add-to-cart-custom .cart-btn-wrap .plus, .add-to-cart-custom .cart-btn-wrap .minus{
  border: none;
  padding: 0px;
  background: #ffc254;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
.add-to-cart-custom .cart-btn-wrap .plus i, .add-to-cart-custom .cart-btn-wrap .minus i{
  line-height: 22px;
  height: 25px;
  width: 25px;
  font-size: 14px;
} */
.add-to-cart .remove i{
  font-size: 18px;
  color: red;
}
.add-to-cart .delete{
  color: rgb(133 128 128);
  background: transparent;
  border: none;
}

/* @media only screen and (max-width: 1201px) { */
  /* .product .add-to-cart .add-to-cart-btn{
    font-size: 10px;
    padding: 0 12px
  } */
  /* .add-to-cart-custom .cart-btn-wrap .qty{
    width: calc(100% - 65px);
  } */
/* } */
.add-to-cart-href{
  padding-top: 15px !important;
}
.product-test .test-param{
  text-align: left;
  padding: 12px 12px 0px;
  /* color: black; */
  /* font-size: .875rem; */
  font-size: 12px;
  /* font-weight: 500; */
  color: #757575;
  height: 103px;
  overflow: auto;
}
.product-test .test-param ul{
  list-style-type: disc;
}
.product-test .test-param .more{
  color: black;
}
/*----------------------------*\
	Widget product
\*----------------------------*/

/* .product-widget {
  position: relative;
}

.product-widget+.product-widget {
  margin: 30px 0px;
}

.product-widget .product-img {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 60px;
}

.product-widget .product-img>img {
  width: 100%;
}

.product-widget .product-body {
  padding-left: 75px;
  min-height: 60px;
}

.product-widget .product-body .product-category {
  text-transform: uppercase;
  font-size: 10px;
  color: #8D99AE;
}

.product-widget .product-body .product-name {
  text-transform: uppercase;
  font-size: 12px;
}

.product-widget .product-body .product-name>a {
  font-weight: 700;
}

.product-widget .product-body .product-name>a:hover, .product-widget .product-body .product-name>a:focus {
  color: #D10024;
}

.product-widget .product-body .product-price {
  font-size: 14px;
  color: #D10024;
}

.product-widget .product-body .product-price .product-old-price {
  font-size: 70%;
  font-weight: 400;
  color: #8D99AE;
}

.product-widget .product-body .product-price .qty {
  font-weight: 400;
  margin-right: 10px;
}

.product-widget .delete {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  text-align: center;
  font-size: 10px;
  padding: 0;
  background: #1e1f29;
  border: none;
  color: #FFF;
} */

/*----------------------------*\
	Products slick
\*----------------------------*/

/* .products-slick .slick-list {
  padding-bottom: 60px;
  margin-bottom: -60px;
  z-index: 2;
}

.products-slick .product.slick-slide {
  margin: 15px;
}

.products-tabs>.tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow-y: hidden;
  padding-bottom: 60px;
  margin-bottom: -60px;
}

.products-tabs>.tab-pane.active {
  opacity: 1;
  visibility: visible;
  height: auto;
}

.products-slick-nav {
  position: absolute;
  right: 15px;
  z-index: 10;
}

.products-slick-nav .slick-prev, .products-slick-nav .slick-next {
  position: static;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  width: 20px;
  height: 20px;
  display: inline-block !important;
  margin: 0px 2px;
}

.products-slick-nav .slick-prev:before, .products-slick-nav .slick-next:before {
  font-size: 14px;
} */

/*=========================================================
	07 -> PRODUCTS PAGE
===========================================================*/

/*----------------------------*\
	Aside
\*----------------------------*/

/* .aside+.aside {
  margin-top: 30px;
}

.aside>.aside-title {
  text-transform: uppercase;
  font-size: 18px;
  margin: 15px 0px 30px;
} */

/*-- checkbox Filter --*/

/* .checkbox-filter>div+div {
  margin-top: 10px;
}

.checkbox-filter .input-radio label, .checkbox-filter .input-checkbox label {
  font-size: 12px;
}

.checkbox-filter .input-radio label small, .checkbox-filter .input-checkbox label small {
  color: #8D99AE;
}  */

/*-- Price Filter --*/

/* #price-slider {
  margin-bottom: 15px;
}

.noUi-target {
  background-color: #FFF;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #E4E7ED;
  border-radius: 0px;
}

.noUi-connect {
  background-color: #D10024;
}

.noUi-horizontal {
  height: 6px;
}

.noUi-horizontal .noUi-handle {
  width: 12px;
  height: 12px;
  left: -6px;
  top: -4px;
  border: none;
  background: #D10024;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 50%;
}

.noUi-handle:before, .noUi-handle:after {
  display: none;
}

.price-filter .input-number {
  display: inline-block;
  width: calc(50% - 7px);
} */



/*----------------------------*\
	Store
\*----------------------------*/

/* .store-filter {
  margin-bottom: 15px;
  margin-top: 15px;
} */

/*-- Store Sort --*/

/* .store-sort {
  display: inline-block;
}

.store-sort label {
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 15px;
} */

/*-- Store Grid --*/

/* .store-grid {
  float: right;
}

.store-grid li {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #FFF;
  border: 1px solid #E4E7ED;
  text-align: center;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.store-grid li+li {
  margin-left: 5px;
}

.store-grid li:hover {
  background-color: #E4E7ED;
  color: #D10024;
}

.store-grid li.active {
  background-color: #D10024;
  border-color: #D10024;
  color: #FFF;
  cursor: default;
}

.store-grid li a {
  display: block;
} */

/*-- Store Pagination --*/

/* .store-pagination {
  float: right;
}

.store-pagination li {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #FFF;
  border: 1px solid #E4E7ED;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.store-pagination li+li {
  margin-left: 5px;
}

.store-pagination li:hover {
  background-color: #E4E7ED;
  color: #D10024;
}

.store-pagination li.active {
  background-color: #D10024;
  border-color: #D10024;
  color: #FFF;
  font-weight: 500;
  cursor: default;
}

.store-pagination li a {
  display: block;
}

.store-qty {
  margin-right: 30px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
} */


/*=========================================================
	08 -> PRODUCT DETAILS PAGE
===========================================================*/

/*----------------------------*\
	Product view
\*----------------------------*/

#product-main-img .slick-prev {
  -webkit-transform: translateX(-15px);
  -ms-transform: translateX(-15px);
  transform: translateX(-15px);
  left: 15px;
}

#product-main-img .slick-next {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
  right: 15px;
}

#product-main-img .slick-prev, #product-main-img .slick-next {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

#product-main-img:hover .slick-prev, #product-main-img:hover .slick-next {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  opacity: 1;
  visibility: visible;
}

#product-main-img .zoomImg {
  background-color: #FFF;
}

#product-imgs .product-preview {
  margin: 0px 5px;
  border: 1px solid #E4E7ED;
}

#product-imgs .product-preview.slick-current {
  border-color: #D10024;
}

#product-imgs .slick-prev {
  top: -20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

#product-imgs .slick-next {
  top: calc(100% - 20px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

#product-imgs .slick-prev:before {
  content: "\f106";
}

#product-imgs .slick-next:before {
  content: "\f107";
}

.product-preview img {
  width: 100%;
}

/*----------------------------*\
	Product details
\*----------------------------*/

.product-details .product-name {
  text-transform: uppercase;
  font-size: 18px;
}

.product-details .product-rating {
  display: inline-block;
  margin-right: 15px;
}

.product-details .product-rating>i {
  color: #E4E7ED;
}

.product-details .product-rating>i.fa-star {
  color: #D10024;
}

.product-details .review-link {
  font-size: 12px;
}

.product-details .product-price {
  display: inline-block;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 15px;
  color: #D10024;
}

.product-details .product-price .product-old-price {
  font-size: 70%;
  font-weight: 400;
  color: #8D99AE;
}

.product-details .product-available {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  margin-left: 30px;
  color: #D10024;
}

.product-details .product-options {
  margin-top: 30px;
  margin-bottom: 30px;
}

.product-details .product-options label {
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 15px;
  margin-bottom: 0px;
}

.product-details .product-options .input-select {
  width: 90px;
}

.product-details .add-to-cart {
  margin-bottom: 30px;
}

.product-details .add-to-cart .add-to-cart-btn {
  position: relative;
  border: 2px solid transparent;
  height: 40px;
  padding: 0 30px;
  background-color: #ef233c;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 40px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.product-details .add-to-cart .add-to-cart-btn>i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  line-height: 38px;
  color: #D10024;
  opacity: 0;
  visibility: hidden;
}

.product-details .add-to-cart .add-to-cart-btn:hover {
  background-color: #FFF;
  color: #D10024;
  border-color: #D10024;
  padding: 0px 30px 0px 50px;
}

.product-details .add-to-cart .add-to-cart-btn:hover>i {
  opacity: 1;
  visibility: visible;
}

.product-details .add-to-cart .qty-label {
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 15px;
  margin-bottom: 0px;
}

.product-details .add-to-cart .qty-label .input-number {
  width: 90px;
  display: inline-block;
}

.product-details .product-btns li {
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
}

.product-details .product-btns li+li {
  margin-left: 15px;
}

.product-details .product-links {
  margin-top: 15px;
}

.product-details .product-links li {
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
}

.product-details .product-links li+li {
  margin-left: 10px;
}
.product-custom:hover{
  box-shadow: none;
}
.product-custom .return-label{
  display: flow-root;
}
/* @media only screen and (max-width: 767px) { */
  .product-custom {
    border: none;
    box-shadow: none;
  }
/* } */
/*----------------------------*\
	 Product tab
\*----------------------------*/

#product-tab {
  margin-top: 60px;
}

#product-tab .tab-nav {
  position: relative;
  text-align: center;
  padding: 15px 0px;
  margin-bottom: 30px;
}

#product-tab .tab-nav:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
  background-color: #E4E7ED;
  z-index: -1;
}

#product-tab .tab-nav li {
  display: inline-block;
  background: #FFF;
  padding: 0px 15px;
}

#product-tab .tab-nav li+li {
  margin-left: 15px;
}

#product-tab .tab-nav li a {
  display: block;
  font-weight: 700;
  color: #8D99AE;
}

#product-tab .tab-nav li.active a {
  color: #D10024;
}

#product-tab .tab-nav li a:after {
  content: "";
  display: block;
  width: 0%;
  height: 2px;
  background-color: #D10024;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

#product-tab .tab-nav li a:hover:after, #product-tab .tab-nav li a:focus:after, #product-tab .tab-nav li.active a:after {
  width: 100%;
}


/*-- Rating --*/

/* .rating-avg {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}

.rating-avg .rating-stars {
  margin-left: 10px;
}

.rating-avg .rating-stars, .rating .rating-stars {
  display: inline-block;
}

.rating-avg .rating-stars>i, .rating .rating-stars>i {
  color: #E4E7ED;
}

.rating-avg .rating-stars>i.fa-star, .rating .rating-stars>i.fa-star {
  color: #D10024;
}

.rating li {
  margin: 5px 0px;
}

.rating .rating-progress {
  position: relative;
  display: inline-block;
  height: 9px;
  background-color: #E4E7ED;
  width: 120px;
  margin: 0px 10px;
  border-radius: 5px;
}

.rating .rating-progress>div {
  background-color: #D10024;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
}

.rating .sum {
  display: inline-block;
  font-size: 12px;
  color: #8D99AE;
} */

/*-- Reviews --*/

/* .reviews li {
  position: relative;
  padding-left: 145px;
  margin-bottom: 30px;
}

.reviews .review-heading {
  position: absolute;
  width: 130px;
  left: 0;
  top: 0;
  height: 70px;
}

.reviews .review-body {
  min-height: 70px;
}

.reviews .review-heading .name {
  margin-bottom: 5px;
  margin-top: 0px;
}

.reviews .review-heading .date {
  color: #8D99AE;
  font-size: 10px;
  margin: 0;
}

.reviews .review-heading .review-rating {
  margin-top: 5px;
}

.reviews .review-heading .review-rating>i {
  color: #E4E7ED;
}

.reviews .review-heading .review-rating>i.fa-star {
  color: #D10024;
}

.reviews-pagination {
  text-align: center;
}

.reviews-pagination li {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background-color: #FFF;
  border: 1px solid #E4E7ED;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.reviews-pagination li:hover {
  background-color: #E4E7ED;
  color: #D10024;
}

.reviews-pagination li.active {
  background-color: #D10024;
  border-color: #D10024;
  color: #FFF;
  cursor: default;
}

.reviews-pagination li a {
  display: block;
}*/

/*-- Review Form --*/

/* .review-form .input {
  margin-bottom: 15px;
}

.review-form .input-rating {
  margin-bottom: 15px;
}

.review-form .input-rating .stars {
  display: inline-block;
  vertical-align: top;
}

.review-form .input-rating .stars input[type="radio"] {
  display: none;
}

.review-form .input-rating .stars>label {
  float: right;
  cursor: pointer;
  padding: 0px 3px;
  margin: 0px;
}

.review-form .input-rating .stars>label:before {
  content: "\f006";
  font-family: FontAwesome;
  color: #E4E7ED;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.review-form .input-rating .stars>label:hover:before, .review-form .input-rating .stars>label:hover~label:before {
  color: #D10024;
}

.review-form .input-rating .stars>input:checked label:before, .review-form .input-rating .stars>input:checked~label:before {
  content: "\f005";
  color: #D10024;
}  */


/*=========================================================
	09 -> CHECKOUT PAGE
===========================================================*/

/* .billing-details {
  margin-bottom: 30px;
}

.shiping-details {
  margin-bottom: 30px;
}

.order-details {
  position: relative;
  padding: 0px 30px 30px;
  border-right: 1px solid #E4E7ED;
  border-left: 1px solid #E4E7ED;
  border-bottom: 1px solid #E4E7ED;
}

.order-details:before {
  content: "";
  position: absolute;
  left: -1px;
  right: -1px;
  top: -15px;
  height: 30px;
  border-top: 1px solid #E4E7ED;
  border-left: 1px solid #E4E7ED;
  border-right: 1px solid #E4E7ED;
}

.order-summary {
  margin: 15px 0px;
}

.order-summary .order-col {
  display: table;
  width: 100%;
}

.order-summary .order-col:after {
  content: "";
  display: block;
  clear: both;
}

.order-summary .order-col>div {
  display: table-cell;
  padding: 10px 0px;
}

.order-summary .order-col>div:first-child {
  width: calc(100% - 150px);
}

.order-summary .order-col>div:last-child {
  width: 150px;
  text-align: right;
}

.order-summary .order-col .order-total {
  font-size: 24px;
  color: #D10024;
}

.order-details .payment-method {
  margin: 30px 0px;
}

.order-details .order-submit {
  display: block;
  margin-top: 30px;
} */

/* .btn-primary svg{
  vertical-align: middle;
} */

/*=========================================================
	10 -> NEWSLETTER
===========================================================*/

/* #newsletter.section {
  border-top: 2px solid #E4E7ED;
  border-bottom: 3px solid #D10024;
  margin-top: 30px;
}

.newsletter {
  text-align: center;
  background-color: #15161d;
}

.newsletter p {
  font-size: 24px;
}

.newsletter form {
  position: relative;
  max-width: 520px;
  margin: 30px auto;
}

.newsletter form:after {
  content: "\f003";
  font-family: FontAwesome;
  position: absolute;
  font-size: 160px;
  color: #E4E7ED;
  top: 15px;
  -webkit-transform: translateY(-50%) rotate(15deg);
  -ms-transform: translateY(-50%) rotate(15deg);
  transform: translateY(-50%) rotate(15deg);
  z-index: -1;
  left: -90px;
}

.newsletter form .input {
  width: calc(100% - 160px);
  margin-right: -4px;
  border-radius: 40px 0px 0px 40px;
}

.newsletter form .newsletter-btn {
  width: 160px;
  height: 41px;
  font-weight: 700;
  background: #D10024;
  color: #FFF;
  border: none;
  border-radius: 0px 40px 40px 0px;
}

.newsletter .newsletter-follow {
  text-align: center;
}

.newsletter .newsletter-follow li {
  display: inline-block;
  margin-right: 5px;
}

.newsletter .newsletter-follow li:last-child {
  margin-right: 0px;
}

.newsletter .newsletter-follow li a {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #E4E7ED;
  background-color: #FFF;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.newsletter .newsletter-follow li a:hover, .newsletter .newsletter-follow li a:focus {
  background-color: #E4E7ED;
  color: #D10024;
} */

/*=========================================================
	11 -> FOOTER
===========================================================*/

#footer {
  /* background: #15161D; */
  background: var(--secondary);
  /* color: #B9BABC; */
  color: #fff;
}

#bottom-footer {
  background: #1E1F29;
}

.footer {
  margin: 30px 0px;
}

.footer .footer-title {
  color: #FFF;
  text-transform: uppercase;
  font-size: 18px;
  margin: 0px 0px 30px;
}

.footer-links li{
  display: flex;
  align-items: center;
}

.footer-links li+li {
  margin-top: 15px;
}

.footer-links li a {
  /* color: #B9BABC; */
  color: #fff;
}

.footer-links li i {
  margin-right: 15px;
  color: #fff;
  width: 14px;
  text-align: center;
}

.footer-links li a:hover {
  /* color: var(--secondary); */
  color: var(--primary);
}

.copyright {
  margin-top: 30px;
  display: block;
  font-size: 12px;
}

.footer-payments li {
  display: inline-block;
  margin-right: 5px;
}

.footer-payments li a {
  color: #15161D;
  font-size: 36px;
  display: block;
}

/*=========================================================
	12 -> SLICK STYLE
===========================================================*/

/*----------------------------*\
	Arrows
\*----------------------------*/

/* .slick-prev, .slick-next {
  width: 40px;
  height: 40px;
  border: 1px solid #E4E7ED;
  background-color: #FFF;
  border-radius: 50%;
  z-index: 22;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  background-color: #D10024;
  border-color: #D10024;
}

.slick-prev:before, .slick-next:before {
  font-family: FontAwesome;
  color: #2B2D42;
}

.slick-prev:before {
  content: "\f104";
}

.slick-next:before {
  content: "\f105";
}

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  color: #FFF;
}

.slick-prev {
  left: -20px;
}

.slick-next {
  right: -20px;
} */

/*----------------------------*\
	Dots
\*----------------------------*/

/* .slick-dots li, .slick-dots li button, .slick-dots li button:before {
  width: 10px;
  height: 10px;
}

.slick-dots li button:before {
  content: "";
  opacity: 1;
  background: #E4E7ED;
  border-radius: 50%;
}

.slick-dots li.slick-active button:before {
  background-color: #D10024;
}

.custom-dots .slick-dots {
  position: static;
  margin: 15px 0px;
} */
/* label{
  font-weight: 700;
} */
.ck.ck-dropdown .ck-dropdown__arrow{
  z-index: 0 !important;
}
.form-label{
  font-weight: 700;
}
em{
  color: red;
}
.custom-file{
  /* width: 100%; */
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(calc(2.25rem + 2px) - 1px * 2);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 .25rem .25rem 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}
.modal-header button{
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em .25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: .25rem;
  opacity: .5;
}
.mb-15{
  margin-bottom: 15px;
}
.section-title h3:before {
  position: absolute;
  left: 50%;
  margin-left: -25px;
  bottom: -1px;
  height: 2px;
  width: 50px;
  background: #d2342d;
  content: "";
}
.section-title {
  text-align: center;
  margin-bottom: 25px;
  /* padding: 0 300px; */
  position: relative;
  z-index: 5;
  text-transform: capitalize;
}
/* ScrollBar */
/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%); 
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: hsla(0,0%,0%,0.2); 
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%); 
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #EBECED; 
}
.btn-link:hover{
  text-decoration: none;
}
.modal-dialog-centered {
  display: grid;
  align-items: center;
  min-height: calc(100% - 1rem);
}
button:focus{
  outline: none;
  box-shadow: none !important;
}
.black-bold{
  color: #000;
  font-weight: 700;
}
.font-20{
  font-size: 20px;
}
.font-15{
  font-size: 15px;
}
.font-18{
  font-size: 18px;
}
.green-btn{
  transition: all .3s ease;
  height: 44px;
  padding: 12px 20px;
  font-size: 13px;
  font-weight: 700;
  width: 211px;
  color: #fff;
  /* border-radius: 4px 2px 2px 4px; */
  border-radius: 4px;
  border: 1px solid transparent;
  background: #66af77;
  border-color: #57bb63;
}
/* @media only screen and (min-width: 767px) {
  .modal{
    top: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .modal{
    top: 130px;
  }
} */
.description{
  color: #525050;
}
.description ul{
  list-style-type: disc;
  margin-left: 19px;
}
.description ol{
  list-style-type: decimal;
  margin-left: 19px;
}
.description b{
  font-weight: 500;
}
.nav-link{
  color: #2B2D42;
}
.text-right{
  text-align: right;
}
.inline-code{
  color: black !important;
  height: 44px;
  line-height: 42px;
  /* display: flex !important; */
}
.static-pages{
  font-family: helvetica,Helvetica Neue,Arial,Lucida Grande,sans-serif;
  font-size: 15px;
}
.static-pages ul{
  /* color: #333; */
  color: var(--black);
}
.static-pages .accordion-button{
  padding: 13px 13px;
  color: var(--black);
}
.accordion-button:not(.collapsed){
  background-color: #fff6e5;
}
.accordion-button:not(.collapsed)::after, .accordion-button::after{
  filter: brightness(0.5) sepia(1) hue-rotate(-70deg) saturate(5);
}
/* .slick-slider{
  display: block;
}
.slick-list{
  width: 100%;
}*/
.slick-track{
  display: flex;
}
.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
/* Slider Design: Small Icon */
.category{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ebebeb;
  margin: auto;
}
@media only screen and (max-width: 500px) {
  .category{
    width: 60px;
    height: 60px;
  }
}
.category img{
  max-width: 100%;
}
.category-title{
  height: 20px;
}
.category-title p{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
.slick-next:before, .slick-prev:before{
  color: var(--secondary);
  /* font-size: 25px; */
  font-size: 35px;
}
.slick-prev {
  /* left: -29px; */
  /* left: -10px; */
  left: 6%;
  z-index: 999;
}
.slick-next {
  right: -7px;
  z-index: 999;
  right: 6%;
}
.slick-next, .slick-prev{
  top: 56%;
}
.slick-dots li.slick-active button:before, .slick-dots li button:before{
  color: var(--secondary);
  font-size: 10px;
}
/* Banner */
.banner-section .slick-track{
  height: 307px;
}
.banner-wrapper{
  height: 307px;
  width: 100%;
  overflow: hidden;
}
.banner{
  position: relative;
  height: 307px;
}
.banner img{
  position: absolute;
  height: 307px;
  /* min-width: 1680px; */
  width: 100%;
  /* left: 50%; */
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
/* Horizontal Product List */
.pro-hr .title{
  padding: 12px;
  /* border-bottom: 1px solid rgba(0,0,0,.1); */
  border-bottom: 1px solid rgb(127 28 23 / 13%);
}
.pro-hr h2{
  font-size: 18px;
  line-height: 32px;
  display: inline-block;
  font-weight: 500;
}
.pro-hr .title a{
  float: right;
  /* background: var(--black); */
  color: #fff;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  border: none;
  /* padding: 8px 15px; */
  padding: 6px 12px;
  background: linear-gradient(90deg, rgb(41 108 186) 0%, rgb(207 55 121) 100%);
}
/* .product-title{
  font-size: 16px;
  font-weight: 500;
} */
h1.product-title{
  /* text-transform: uppercase; */
  font-size: 1.625rem;
  font-weight: 600;
}
.product-brand{
  color: #801f1a75;
  font-weight: 600;
}
.offer-wrap{
  text-align: center;
  border: 2px dotted #ffa73c;
  border-radius: 15px;
  margin: 0px 10px;
  /* padding: 12px; */
  padding-top: 12px;
}
.offer-wrap .offer-desc{
  font-size: 15px;
  color: green;
  padding: 3px 5px;
}
.offer-wrap .promo{
  /* background: #ccc; */
  background: #ffa73c59;
  /* padding: 3px; */
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  padding: 5px 6px;
}
.offer-wrap .promo-wrap{
  padding: 12px;
  /* background-color: #f1f1f1; */
  background: #fff6e5;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
/* .star {
  height: 100px;
  width: 100px;
  -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  text-align: center;
  background: red;
  color: white;
}

.star::before {
  display: inline-block;
  height: 100%;
  background: blue;
  vertical-align: middle;
  content: '';
 */
.star-eight {
  /* margin: 2em; */
  margin: 20px auto;
  border: 2px solid red;
  background: white;
  width: 65px;
  height: 65px;
  position: relative;
  transform: rotate(20deg);
  display:inline-flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  box-sizing: content-box;
}
.star-eight .content {
  padding:10px;
  font-size:13px;
  color: var(--primary);
  transform: rotate(-20deg);
  z-index:1;
  font-weight: 600;
  border-radius: 50%;
  background: white;
  margin: 5px;
}
.star-eight:before {
  content: "";
  position: absolute;
  z-index:-1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* background: white; */
  background: repeating-conic-gradient(red 10%, yellow 20%);
  transform: rotate(135deg);
  box-shadow: 0 0 0 2px var(--primary);
}
.star-eight:after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* background: linear-gradient(to bottom left, white 17px, transparent 15px, transparent 95px, white 95px), */
     /* linear-gradient(to bottom right, white 93px, transparent 15px, transparent 95px, white 95px); */
  background: repeating-conic-gradient(red 10%, yellow 20%);
}
.drop-btn .dropdown-header,
.drop-btn .add-to-cart-btn{
  text-transform: none !important;
}
.drop-btn .dropdown-toggle::after{
  right: 12px;
  top: 50%;
  transform: translate(0,-50%);
  position: absolute;
  font-size: 17px;
  color: #D10024;
}
.drop-btn .dropdown-item.active,
.drop-btn .dropdown-item:active{
  background: #ffa5001a;
  color: var(--black);
}
.drop-btn .dropdown-item{
  padding: 11px 20px;
  font-size: 1rem;
  font-weight: 500;
  /* color: inherit; */
  line-height: 20px;
}
.drop-btn .dropdown-menu{
  /* min-width: 85%; */
  z-index: 22;
  height: 200px;
  overflow: auto;
  overflow-x: hidden;
}
.custom-dropdown .select-custom {
  /* background-color: #4CAF50; */
  /* color: white; */
  padding: 1px 5px;
  font-size: 14px;
  /* border: none; */
  cursor: pointer;
  background: #ffa5001a;
  /* outline: none; */
  border: 1px solid #7f090921;
  color: var(--black);
  text-align: left;
  margin-bottom: 0px;
  /* white-space: nowrap; */
  /* width: 100%; */
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
  display: inline-flex;
  /* width: 100%; */
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-dropdown .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  /* min-width: 160px; */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  width: 100%;
  text-align: left;
  border: 1px solid var(--black);
}

.custom-dropdown .dropdown-content button {
  color: var(--black);
  text-decoration: none;
  display: block;
  padding: 3px 8px;
  font-weight: 400;
  background: #f9f9f9;
  border: none;
  width: 100%;
  text-align: left;
}

.custom-dropdown .dropdown-content button:hover {
  /* background-color: #f1f1f1; */
  background: #f1e8d8;
  font-weight: 500;
}

/* .custom-dropdown:hover .dropdown-content {
  display: block;
} */

/* .custom-dropdown:hover .select-custom {
  background-color: #3e8e41;
} */
.custom-dropdown .select-custom i{
  float: right;
  font-size: 10px;
  padding: 4px 0px 3px 4px;
}
.custom-dropdown .select-custom span, .custom-dropdown .select-custom{
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.fade-title{
  text-align: right;
  margin-bottom: 0px;
  /* color: #d8362fc7; */
  font-weight: 500;
  /* color: #ddbebcbf; */
  color: #7f09099e;
  font-size: 13px;
  margin-top: 7px;
}
.no-product{
  text-align: center;
}
.no-product img{
  height: 75px;
}
.no-product .title{
  font-size: 18px;
  font-weight: 500;
}
.no-product .sub-title{
  font-size: 16px;
}
.error{
  color: red;
}
.search-box-wrap{
  display: inline-flex;
  padding: 12px 18px 8px;
}
/* .checkout-section #div-float{
  position: fixed;
  padding: 15px 30px;
} */
.checkout-section .card{
  padding: 5px 30px 10px;
}
.checkout-section button{
  width: 100%;
}
.checkout-section table tr td:last-child{
  text-align: right;
}
.checkout-section .checkout-btn{
  padding: 10px;
}
.checkout-section .checkout-mode{
  font-size: 18px;
}
.gif-img{
  width: 200px;
}
.scrollable-div{
  max-height: 65vh;
  overflow: auto;
  overflow-x: hidden;
}
.link-custom{
  color: var(--black);
  font-weight: 500;
  font-size: 14px;
}
/* .menu-toggle.dropdown:hover .dropdown-menu {
  display: block;
} */
/* .bottom-div{
  position: absolute;
  bottom: 0px;
} */
.modal-footer{
  justify-content: space-between;
}
.carousel-indicators [data-bs-target], .carousel-control-prev-icon, .carousel-control-next-icon{
  background-color: var(--secondary);
}
.list-group-bullet{
  list-style-type: disc;
}
/*=========================================================
	Banner Home Page
===========================================================*/
.banner-main{
  /* padding-top: 85px;
  padding-bottom: 85px; */
  /* padding-bottom: 125px; */
  padding-top: 60px;
  padding-bottom: 75px;
}
.banner-main {
  background-image: url(../images/home-bg.png);
  /* background-position: top center; */
  /* background-size: cover; */
  /* color: #b2dded; */
  /* position: absolute; */
  width: 100%;
  min-height: 100%;
  /* top: 0; */
  /* left: 0; */
  background-size: cover;
  background-position: center;
  overflow: hidden;
  will-change: transform, opacity, filter;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  z-index: 0;
}
.banner-main img{
  /* border-radius: 40% 30% 22% 86% / 60% 40% 90% 40%; */
  /* height: 100%; */
  border-radius: 57% 43% 45% 55% / 58% 61% 39% 42%;
  height: 120%;
}
.banner-main .img-wrapper{
  z-index: 2;
}
.banner-main h1{
  /* font-size: 2.98598rem !important; */
  font-size: 40px !important;
}
.banner-main .head-wrapper{
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}
.banner-main .head-wrapper p{
  font-size: 19px;
  color: #1B71A1;
  font-weight: 500;
}
.card-content{
  background-image: url(../images/content-bg.jpg);
  background-size: cover;
  border: 1px solid var(--primary-light);
  border-radius: 5px;
  padding: 10px;
}
.card-content .card::before{
  content:"";
  position: absolute;
  inset: 0;
  transform: translate3d(0,0,-1px);
  background: conic-gradient(from 90deg at 40% -25%, #ffd700, #f79d03, #ee6907, #e6390a, #de0d0d, #d61039, #cf1261, #c71585, #cf1261, #d61039, #de0d0d, #ee6907, #f79d03, #ffd700, #ffd700, #ffd700);
  filter: blur(10px);
  clip-path: polygon(-100vmax -100vmax,100vmax -100vmax,100vmax 100vmax,-100vmax 100vmax,-100vmax -100vmax,0 0,0 100%,100% 100%,100% 0,0 0);
}
.section-cat-wrapper{
  background: white;
}
.section-lg-wrapper{
  background: var(--primary);
}
.feature-sec{
  display: inline-flex;
  padding: 18px 12px;
}
.feature-sec p{
  margin-bottom: 0px;
}
.feature-sec img{
  height: 60px;
  width: 60px;
}
/*=========================================================
	13 -> RESPONSIVE
===========================================================*/
.responsive-y{
  overflow: hidden;
  overflow-y: auto;
}
@media only screen and (max-width: 270px) {
  .top-nav p{
    font-size: 12px;
  }
}
@media only screen and (max-width: 1201px) {}
@media only screen and (max-width: 573px) {
  .product .product-img>img{
    padding: 0px;
    width: 100%;
    height: auto;
  }
}
@media only screen and (max-width: 991px) {
  #top-header .header-links.pull-left {
    float: none !important;
  }
  #top-header .header-links.pull-right {
    float: none !important;
    margin-top: 5px;
  }
  .header-logo {
    float: none;
    text-align: center;
  }
  /* .header-logo .logo {
    display: inline-block;
  } */
  #product-imgs {
    margin-bottom: 60px;
    margin-top: 15px;
  }
  #rating {
    text-align: center;
  }
  #reviews {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title .section-nav {
    float: none;
    margin-top: 10px;
  }
  .section-tab-nav li {
    margin-top: 10px;
  }
}
@media screen and (max-width: 371px){
  .banner-main .head-wrapper .btn{
    font-size: 13px;
    padding: 5px 8px;
    margin-bottom: 30px;
    /* margin-bottom: 300px; */
  }
  .match-pro .price-cart{
    display: block !important;
  }
  .pro-hr h2{
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) and (min-width: 300px){
  .banner-main{
    padding-top: 20px;
    padding-bottom: 0px;
    background-color: var(--primary);
    background-image: none;
  }
  .banner-main h1{
    font-size: 30px !important;
  }
  .banner-main img{
    /* height: 100%; */
    width: 75% !important;
  } 
  .banner-main .head-wrapper {
    padding-top: 40px !important;
    /* padding-left: 60px; */
    padding-bottom: 0px !important;
  }
}
@media screen and (max-width: 991px) and (min-width: 768px){
  .banner-main{
    padding-top: 40px !important;
    padding-bottom: 0px;
  }
  .banner-main h1{
    font-size: 25px !important;
  }
  .banner-main img{
    height: 100%;
  }
  .banner-main .head-wrapper {
    padding-top: 5px !important;
    /* padding-left: 60px; */
    /* padding-bottom: 4rem !important; */
  }
}
@media screen and (max-width: 1155px) and (min-width: 992px){
  /* .header-ctn {
    float: right;
  } */
  .banner-main {
    /* padding-top: 55px !important; */
    /* padding-bottom: 0px; */
    /* padding-bottom: 125px; */
    padding-top: 45px !important;
    padding-bottom: 17px;
  }
  .banner-main h1{
    font-size: 35px !important;
  }
  .banner-main img{
    height: 100%;
  }
  .banner-main .head-wrapper {
    padding-top: 34px !important;
    padding-left: 60px;
    /* padding-bottom: 4rem !important; */
  }
}
@media only screen and (max-width: 1300px){
  .header-ctn>div>a>.qty{
    right: -10px;
    top: -15px;
  }
}
@media only screen and (max-width: 480px) {
  [class*='col-xs'] {
    width: 100%;
  }
  .store-grid {
    float: none;
    margin-top: 10px;
  }
  .store-pagination {
    float: none;
    margin-top: 10px;
  }
  .add-to-cart-btn{
    font-size: 11px;
    padding: 0 12px;
  }
}
.screen-overlay{
  opacity:    0.5; 
  background: #000; 
  width:      100%;
  height:     100%; 
  z-index:    9999;
  top:        0; 
  left:       0; 
  position:   fixed;
}
/* #bodyOverlay svg{
  display: none;
} */
#bodyOverlay{
  display: none;
}
.screen-overlay svg{
  position: absolute;
  top: 50%;
  margin-top: -20px;
  left: 50%;
  margin-left: -20px;
}
/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  /* background: var(--secondary); */
  background: linear-gradient(90deg, rgb(41 108 186) 0%, rgb(207 55 121) 100%);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 20px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #f94a6a;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}
/* .opacity-0{
  opacity: 0;
}
.opacity-1{
  opacity: 1;
} */
.mob-txt{
  clear: both;
  /* display: inline-block;
  overflow: hidden; */
  white-space: nowrap;
}